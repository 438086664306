// import { useState, useEffect } from 'react';
// import { getAuth, onAuthStateChanged } from 'firebase/auth';

// export function useAuth() {
//   const [currentUser, setCurrentUser] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const auth = getAuth();
//     const unsubscribe = onAuthStateChanged(auth, user => {
//       setCurrentUser(user);
//       setIsLoading(false);
//     });
//     return unsubscribe; // This function is called when the component is unmounted
//   }, []);

//   return { currentUser, isLoading };
// }

// useAuth.js
// useAuth.js


import { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from './firebase-config';

export function useAuth() {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setIsLoading(false);
      console.log('Current user:', user);
    });

    return () => unsubscribe();
  }, []);

  const checkSubscriptionStatus = async (userId) => {
    if (!userId) {
      console.log('No user ID provided');
      return false;
    }

    try {
      const subscriptionRef = collection(db, 'subscription_tokens', userId, 'appointments_bot');
      const q = query(subscriptionRef, where('isActive', '==', true));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        console.log('User has an active subscription');
        return true;
      } else {
        console.log('User does not have an active subscription');
        return false;
      }
    } catch (error) {
      console.error('Error fetching subscription data:', error);
      return false;
    }
  };


  const checkVoicebotSubscriptionStatus = async (userId) => {
    if (!userId) {
      console.log('No user ID provided');
      return false;
    }

    try {
      const subscriptionRef = collection(db, 'subscription_tokens', userId, 'voicebot');
      const q = query(subscriptionRef, where('isActive', '==', true));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        console.log('User has an active subscription');
        return true;
      } else {
        console.log('User does not have an active subscription');
        return false;
      }
    } catch (error) {
      console.error('Error fetching subscription data:', error);
      return false;
    }
  };


  return { currentUser, isLoading, checkSubscriptionStatus ,checkVoicebotSubscriptionStatus};
}