// import React from 'react';
// import {
//   Card,
//   CardContent,
//   Typography,
//   Box,
//   Grid,
//   LinearProgress,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper
// } from '@mui/material';
// import { formatCurrency } from '../../utils/formatters';

// const UsageCard = ({ usage = { records: [], totalCost: 0, totalMinutesUsed: 0 }, 
//                     subscription = { monthlyMinutes: 0, minutesUsed: 0 } }) => {
  
//   // Guard against null values
//   if (!usage || !subscription) {
//     return (
//       <Card>
//         <CardContent>
//           <Typography variant="h6">No usage data available</Typography>
//         </CardContent>
//       </Card>
//     );
//   }

//   // Ensure records exists and is an array
//   const records = usage.records || [];
//   const totalCost = usage.totalCost || 0;
//   const totalMinutesUsed = usage.totalMinutesUsed || 0;
//   const monthlyMinutes = subscription.monthlyMinutes || 0;
//   const minutesUsed = subscription.minutesUsed || 0;

//   // Calculate daily usage
//   const dailyUsage = records.reduce((acc, record) => {
//     if (!record) return acc;

//     const date = new Date(record.timestamp).toLocaleDateString();
//     if (!acc[date]) {
//       acc[date] = {
//         minutes: 0,
//         subscriptionMinutes: 0,
//         paidMinutes: 0,
//         totalCost: 0,
//         calls: 0
//       };
//     }
//     acc[date].minutes += record.minutes || 0;
//     acc[date].calls += 1;
//     acc[date].totalCost += record.totalCost || 0;
    
//     // Split between subscription and paid minutes
//     if (record.minutesFromSubscription > 0) {
//       acc[date].subscriptionMinutes += record.minutesFromSubscription;
//     }
//     if (record.minutesCharged > 0) {
//       acc[date].paidMinutes += record.minutesCharged;
//     }
    
//     return acc;
//   }, {});

//   // Calculate remaining minutes with safety checks
//   const minutesRemaining = Math.max(0, monthlyMinutes - minutesUsed);
//   const minutesPercentage = monthlyMinutes > 0 
//     ? Math.min(100, (minutesUsed / monthlyMinutes) * 100)
//     : 0;

//   return (
//     <Card>
//       <CardContent>
//         <Typography variant="h6" gutterBottom>
//           Usage Summary
//         </Typography>

//         <Box mb={4}>
//           <Grid container spacing={3}>
//             <Grid item xs={12} md={6}>
//               <Typography variant="subtitle2" color="textSecondary" gutterBottom>
//                 Subscription Minutes
//               </Typography>
//               <Typography variant="h5" gutterBottom>
//                 {minutesUsed} / {monthlyMinutes} minutes used
//               </Typography>
//               <LinearProgress 
//                 variant="determinate" 
//                 value={minutesPercentage}
//                 color={minutesPercentage > 90 ? 'error' : 'primary'}
//                 sx={{ height: 8, borderRadius: 4, mb: 1 }}
//               />
//               <Typography variant="body2" color={minutesRemaining < 10 ? 'error' : 'textSecondary'}>
//                 {minutesRemaining} minutes remaining
//               </Typography>
//             </Grid>

//             <Grid item xs={12} md={6}>
//               <Typography variant="subtitle2" color="textSecondary" gutterBottom>
//                 Additional Usage This Month
//               </Typography>
//               <Typography variant="h5" color="primary">
//                 {formatCurrency(totalCost)}
//               </Typography>
//               <Typography variant="body2" color="textSecondary">
//                 {Math.max(0, totalMinutesUsed - minutesUsed)} additional minutes used
//               </Typography>
//             </Grid>
//           </Grid>
//         </Box>

//         <Typography variant="h6" gutterBottom>
//           Daily Breakdown
//         </Typography>
        
//         <TableContainer component={Paper} variant="outlined">
//           <Table size="small">
//             <TableHead>
//               <TableRow>
//                 <TableCell>Date</TableCell>
//                 <TableCell align="right">Calls</TableCell>
//                 <TableCell align="right">Subscription Minutes</TableCell>
//                 <TableCell align="right">Additional Minutes</TableCell>
//                 <TableCell align="right">Additional Cost</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {Object.entries(dailyUsage).length > 0 ? (
//                 Object.entries(dailyUsage)
//                   .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA))
//                   .map(([date, data]) => (
//                     <TableRow key={date}>
//                       <TableCell>{date}</TableCell>
//                       <TableCell align="right">{data.calls}</TableCell>
//                       <TableCell align="right">{data.subscriptionMinutes}</TableCell>
//                       <TableCell align="right">{data.paidMinutes}</TableCell>
//                       <TableCell align="right">{formatCurrency(data.totalCost)}</TableCell>
//                     </TableRow>
//                   ))
//               ) : (
//                 <TableRow>
//                   <TableCell colSpan={5} align="center">No usage data available</TableCell>
//                 </TableRow>
//               )}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </CardContent>
//     </Card>
//   );
// };

// export default UsageCard;


import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import { formatCurrency } from '../../utils/formatters';

const UsageCard = ({ usage, billingSummary }) => {
  if (!usage || !billingSummary) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h6">No usage data available</Typography>
        </CardContent>
      </Card>
    );
  }

  // Use billingSummary for the minutes information
  const monthlyMinutes = billingSummary.monthlyMinutes || 0;
  const minutesUsed = billingSummary.minutesUsed || 0;
  //const balance = billingSummary.balance || 0;
  const balance = billingSummary?.balance || {
    total: 0,
    used: 0,
    breakdown: { subscription: 0, topUp: 0 }
  };

  // Calculate remaining minutes
  const minutesRemaining = Math.max(0, balance);
  const minutesPercentage = monthlyMinutes > 0 
    ? Math.min(100, (minutesUsed / monthlyMinutes) * 100)
    : 0;

  // Process daily usage from records
  const dailyUsage = (usage.records || []).reduce((acc, record) => {
    if (!record || !record.timestamp) return acc;

    const date = new Date(record.timestamp).toLocaleDateString();
    if (!acc[date]) {
      acc[date] = {
        minutes: 0,
        calls: 0,
        totalCost: 0,
        subscriptionMinutes: 0,
        paidMinutes: 0
      };
    }
    
    acc[date].minutes += record.minutes || 0;
    acc[date].calls += 1;
    acc[date].totalCost += record.cost || 0;
    
    // Split between subscription and paid minutes if available
    if (record.minutesFromSubscription) {
      acc[date].subscriptionMinutes += record.minutesFromSubscription;
    }
    if (record.minutesCharged) {
      acc[date].paidMinutes += record.minutesCharged;
    }
    
    return acc;
  }, {});

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Usage Summary
        </Typography>

        <Box mb={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                Subscription Minutes
              </Typography>
              <Typography variant="h5" gutterBottom>
                {minutesUsed} / {monthlyMinutes} minutes used
              </Typography>
              <LinearProgress 
                variant="determinate" 
                value={minutesPercentage}
                color={minutesPercentage > 90 ? 'error' : 'primary'}
                sx={{ height: 8, borderRadius: 4, mb: 1 }}
              />
              <Typography variant="body2" color={minutesRemaining < 10 ? 'error' : 'textSecondary'}>
                {minutesRemaining} minutes remaining
              </Typography>
              {billingSummary.breakdown && (
                <Typography variant="caption" color="textSecondary">
                  {billingSummary.breakdown.subscription || 0} from subscription
                  {billingSummary.breakdown.topUp > 0 && 
                    ` + ${billingSummary.breakdown.topUp} from top-up`}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                Additional Usage This Month
              </Typography>
              <Typography variant="h5" color="primary">
                {formatCurrency(usage.totalCost || 0)}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {Math.max(0, (usage.totalMinutesUsed || 0) - minutesUsed)} additional minutes used
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Typography variant="h6" gutterBottom>
          Daily Breakdown
        </Typography>
        
        <TableContainer component={Paper} variant="outlined">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell align="right">Calls</TableCell>
                <TableCell align="right">Subscription Minutes</TableCell>
                <TableCell align="right">Additional Minutes</TableCell>
                <TableCell align="right">Additional Cost</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(dailyUsage).length > 0 ? (
                Object.entries(dailyUsage)
                  .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA))
                  .map(([date, data]) => (
                    <TableRow key={date}>
                      <TableCell>{date}</TableCell>
                      <TableCell align="right">{data.calls}</TableCell>
                      <TableCell align="right">{data.subscriptionMinutes}</TableCell>
                      <TableCell align="right">{data.paidMinutes}</TableCell>
                      <TableCell align="right">{formatCurrency(data.totalCost)}</TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">No usage data available</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default UsageCard;
