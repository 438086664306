import React, { useState } from 'react';
import { getAuth } from 'firebase/auth';
import { db } from '../../firebase-config';
import { collection, addDoc, getDoc, doc, onSnapshot } from 'firebase/firestore';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Alert,
  CircularProgress
} from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

// const CREDIT_OPTIONS = [
//   { amount: 50, bonus: 2 },
//   { amount: 100, bonus: 10 },
//   { amount: 200, bonus: 25 },
//   { amount: 500, bonus: 100 }
// ];

const CREDIT_OPTIONS = [
  { amount: 50, bonus: 2, priceId: 'price_1QRzAsHINCQB1HcDxMddYCPv' },  // Replace with your Stripe price IDs
  // { amount: 100, bonus: 10, priceId: 'price_yyyy' },
  // { amount: 200, bonus: 25, priceId: 'price_zzzz' },
  // { amount: 500, bonus: 100, priceId: 'price_wwww' }
];

const AddCreditsDialog = ({ open, onClose, onSuccess }) => {
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleAddCredits = async () => {
    setLoading(true);
    const currentUser = getAuth().currentUser;
    const selectedOption = CREDIT_OPTIONS.find(opt => opt.amount === selectedAmount);

    if (!currentUser || !selectedOption) {
      setLoading(false);
      return;
    }

    const successUrl = `${window.location.origin}/voicebot`;

    const checkoutSessionData = {
      price: selectedOption.priceId,
      allow_promotion_codes: true,
      success_url: successUrl,
      cancel_url: `${window.location.origin}/voicebot`,
      mode: 'payment',
      metadata: {
        type: 'credit_topup',
        minutes: selectedAmount.toString(),
        bonusMinutes: selectedOption.bonus.toString(),
        totalMinutes: (selectedAmount + selectedOption.bonus).toString()
      }
    };

    try {
      const docRef = await addDoc(
        collection(db, `customers/${currentUser.uid}/checkout_sessions`),
        checkoutSessionData
      );

      onSnapshot(docRef, (doc) => {
        const { error, url } = doc.data();
        if (error) {
          console.error("Checkout session error:", error);
          setLoading(false);
          setError(error.message);
          return;
        }
        if (url) {
          window.location.assign(url);
        }
      });
    } catch (error) {
      console.error("Error creating checkout session:", error);
      setLoading(false);
      setError(error.message);
      toast.error('Failed to process payment');
    }
  };

  // const handleAddCredits = async () => {
  //   try {
  //     setLoading(true);
  //     setError(null);

  //     const auth = getAuth();
  //     const idToken = await auth.currentUser.getIdToken();

  //     // Get payment intent
  //     const response = await fetch(
  //       'https://app.pandorabot.io/o-addCredits',
  //       {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'Authorization': `Bearer ${idToken}`
  //         },
  //         body: JSON.stringify({ amount: selectedAmount })
  //       }
  //     );

  //     if (!response.ok) {
  //       const errorData = await response.json();
  //       throw new Error(errorData.error || 'Failed to process payment');
  //     }

  //     const { clientSecret } = await response.json();

  //     // Handle Stripe payment
  //     const stripe = await stripePromise;
  //     const { error: stripeError } = await stripe.confirmCardPayment(clientSecret);

  //     if (stripeError) {
  //       throw new Error(stripeError.message);
  //     }

  //     toast.success('Credits added successfully');
  //     onSuccess?.();
  //     onClose();
  //   } catch (err) {
  //     console.error('Payment error:', err);
  //     setError(err.message);
  //     toast.error('Failed to add credits');
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <Dialog 
      open={open} 
      onClose={loading ? undefined : onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Add Credits to Your Account</DialogTitle>
      
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          {CREDIT_OPTIONS.map((option) => (
            <Grid item xs={12} sm={6} key={option.amount}>
              <Card 
                sx={{ 
                  cursor: 'pointer',
                  border: selectedAmount === option.amount ? 2 : 1,
                  borderColor: selectedAmount === option.amount ? 'primary.main' : 'grey.300'
                }}
                onClick={() => setSelectedAmount(option.amount)}
              >
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    ${option.amount.toFixed(2)}
                  </Typography>
                  {option.bonus > 0 && (
                    <Typography variant="subtitle2" color="success.main">
                      +${option.bonus.toFixed(2)} bonus
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        <Alert severity="info" sx={{ mt: 2 }}>
          Credits never expire and can be used for any service beyond your subscription minutes.
        </Alert>
      </DialogContent>

      <DialogActions>
        <Button 
          onClick={onClose}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button 
          variant="contained"
          disabled={!selectedAmount || loading}
          onClick={handleAddCredits}
          startIcon={loading && <CircularProgress size={20} />}
        >
          {loading ? 'Processing...' : `Add $${selectedAmount?.toFixed(2) || '0.00'}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCreditsDialog;