

import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  CircularProgress,
  Chip
} from '@mui/material';
import {
  Phone as PhoneIcon,
  Search as SearchIcon,
  Delete as DeleteIcon,
  Settings as SettingsIcon,
  Call as CallIcon
} from '@mui/icons-material';
import toast from 'react-hot-toast';
import { getAuth } from 'firebase/auth';
const API_BASE_URL = 'https://app.pandorabot.io';


// Main phone numbers page component
export const PhoneNumbersPage = () => {
  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Phone Numbers
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PhoneNumberSearch />
        </Grid>
        <Grid item xs={12}>
          <PhoneNumberList />
        </Grid>
      </Grid>
    </Box>
  );
};

export const PhoneNumberSearch = ({ onNumberSelected ,onPurchaseComplete}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [numbers, setNumbers] = useState([]);
    const [purchaseStatus, setPurchaseStatus] = useState({ loading: false, error: null });
    const [searchParams, setSearchParams] = useState({
      country: 'GB',
      type: 'local',  // Changed from numberType to type to match backend
      areaCode: ''
    });
  
    const searchNumbers = async () => {
      setLoading(true);
      setError(null);
      
      try {
        const auth = getAuth();
        const idToken = await auth.currentUser.getIdToken();
        const userId = auth.currentUser.uid;
  
        console.log("Sending search request with params:", searchParams);
  
       // const response = await fetch('http://127.0.0.1:5001/stripe-cde79/us-central1/searchPhoneNumbers', {
        const response = await fetch(`${API_BASE_URL}/o-searchPhoneNumbers`, {

          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          },
          body: JSON.stringify({
            userId,
            country: searchParams.country,
            numberType: searchParams.type,  // Make sure this matches
            areaCode: searchParams.areaCode
          })
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to search numbers');
        }
  
        const data = await response.json();
        console.log("Search response:", data);
        setNumbers(data.numbers || []);
      } catch (err) {
        console.error('Error searching numbers:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    const handlePurchase = async (number) => {
        setPurchaseStatus({ loading: true, error: null });
        try {
          const auth = getAuth();
          const idToken = await auth.currentUser.getIdToken();
          const userId = auth.currentUser.uid;
    
          if (!userId || !idToken) {
            throw new Error('Authentication required');
          }
    
          // Send just the basic information needed - regulatory compliance handled by backend
          const response = await fetch(
            //'http://127.0.0.1:5001/stripe-cde79/us-central1/purchaseNumber',
            `${API_BASE_URL}/o-purchaseNumber`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
              },
              body: JSON.stringify({
                userId,
                phoneNumber: number.phoneNumber,
                numberType: searchParams.type,
                country: searchParams.country
              })
            }
          );
    
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to purchase number');
          }
    
          const result = await response.json();
          
          if (result.success) {
            // Show success message
            toast.success(`Successfully purchased number ${result.phoneNumber}`);
            
            // Call callbacks if provided
            if (onNumberSelected) {
              onNumberSelected(result);
            }
            if (onPurchaseComplete) {
              onPurchaseComplete(result);
            }
    
            // Clear the search results after successful purchase
            setNumbers([]);
          } else {
            throw new Error(result.error || 'Failed to purchase number');
          }
    
        } catch (err) {
          console.error('Error purchasing number:', err);
          setPurchaseStatus({ loading: false, error: err.message });
          toast.error(err.message || 'Failed to purchase number');
        } finally {
          setPurchaseStatus({ loading: false, error: null });
        }
      };
    
      return (
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Search Phone Numbers
            </Typography>
    
            <Grid container spacing={2} sx={{ mb: 3 }}>
              {/* Country Selection */}
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Country</InputLabel>
                  <Select
                    value={searchParams.country}
                    label="Country"
                    onChange={(e) => setSearchParams({ ...searchParams, country: e.target.value })}
                  >
                    <MenuItem value="GB">United Kingdom</MenuItem>
                    <MenuItem value="US">United States</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
    
              {/* Number Type Selection */}
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Number Type</InputLabel>
                  <Select
                    value={searchParams.type}
                    label="Number Type"
                    onChange={(e) => setSearchParams({ ...searchParams, type: e.target.value })}
                  >
                    <MenuItem value="local">Local</MenuItem>
                    <MenuItem value="mobile">Mobile</MenuItem>
                    <MenuItem value="tollfree">Toll Free</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
    
              {/* Area Code Input */}
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Area Code (optional)"
                  value={searchParams.areaCode}
                  onChange={(e) => setSearchParams({ 
                    ...searchParams, 
                    areaCode: e.target.value.replace(/\D/g, '').slice(0, 4)
                  })}
                  inputProps={{ maxLength: 4 }}
                  helperText={searchParams.country === 'GB' ? 'e.g. 0121 for Birmingham' : 'e.g. 212 for New York'}
                />
              </Grid>
            </Grid>
    
            {/* Search Button */}
            <Button
              variant="contained"
              onClick={searchNumbers}
              disabled={loading || purchaseStatus.loading}
              fullWidth
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : 'Search Numbers'}
            </Button>
    
            {/* Error Display */}
            {(error || purchaseStatus.error) && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error || purchaseStatus.error}
              </Alert>
            )}
    
            {/* Results Table */}
            {numbers.length > 0 && (
              <TableContainer component={Paper} sx={{ mt: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Monthly Cost</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {numbers.map((number) => (
                      <TableRow key={number.phoneNumber}>
                        <TableCell>{number.phoneNumber}</TableCell>
                        <TableCell>{number.type}</TableCell>
                        <TableCell>${number.monthlyPrice}/mo</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => handlePurchase(number)}
                            disabled={purchaseStatus.loading}
                          >
                            {purchaseStatus.loading ? (
                              <CircularProgress size={20} color="inherit" />
                            ) : 'Purchase'}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </CardContent>
        </Card>
      );
    };


const PhoneNumberList = () => {
    const [numbers, setNumbers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedNumber, setSelectedNumber] = useState(null);
    const [configDialogOpen, setConfigDialogOpen] = useState(false);
    const [configLoading, setConfigLoading] = useState(false);
    
    // Form state for configuration
    const [configForm, setConfigForm] = useState({
        friendlyName: '',
        voiceWebhook: '',
        smsWebhook: '',
        callHandling: 'ai',
        aiVoice: 'amy'
    });
    
    const fetchNumbers = async () => {
        setLoading(true);
        setError(null);
        try {
        const auth = getAuth();
        const idToken = await auth.currentUser.getIdToken();
    
        //const response = await fetch('http://127.0.0.1:5001/stripe-cde79/us-central1/getPhoneNumbers', {
        const response = await fetch(`${API_BASE_URL}/o-getPhoneNumbers`, {

            method: 'GET',
            headers: {
            'Authorization': `Bearer ${idToken}`
            }
        });
    
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to fetch numbers');
        }
    
        const data = await response.json();
        setNumbers(data.numbers || []);
        } catch (err) {
        console.error('Error fetching numbers:', err);
        setError(err.message);
        toast.error('Failed to fetch phone numbers');
        } finally {
        setLoading(false);
        }
    };
    
    useEffect(() => {
        fetchNumbers();
    }, []);
    
    const handleDeleteNumber = async () => {
        try {
        const auth = getAuth();
        const idToken = await auth.currentUser.getIdToken();
    
        //const response = await fetch('http://127.0.0.1:5001/stripe-cde79/us-central1/releaseNumber', {
        const response = await fetch(`${API_BASE_URL}/o-releaseNumber`, {

            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
            },
            body: JSON.stringify({
            numberId: selectedNumber.id
            })
        });
    
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to release number');
        }
    
        toast.success('Phone number released successfully');
        setDeleteDialogOpen(false);
        fetchNumbers(); // Refresh the list
        } catch (err) {
        console.error('Error releasing number:', err);
        toast.error(err.message || 'Failed to release phone number');
        }
    };
    
    const handleConfigureSave = async () => {
        setConfigLoading(true);
        try {
        const auth = getAuth();
        const idToken = await auth.currentUser.getIdToken();
    
        //const response = await fetch('http://127.0.0.1:5001/stripe-cde79/us-central1/configureNumber', {
        const response = await fetch(`${API_BASE_URL}/o-configureNumber`, {

            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
            },
            body: JSON.stringify({
            numberId: selectedNumber.id,
            config: configForm
            })
        });
    
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to update configuration');
        }
    
        toast.success('Configuration updated successfully');
        setConfigDialogOpen(false);
        fetchNumbers(); // Refresh the list
        } catch (err) {
        console.error('Error updating configuration:', err);
        toast.error(err.message || 'Failed to update configuration');
        } finally {
        setConfigLoading(false);
        }
    };
    
    const openConfigDialog = (number) => {
        setSelectedNumber(number);
        setConfigForm({
        friendlyName: number.friendlyName || '',
        voiceWebhook: number.webhooks?.voice || '',
        smsWebhook: number.webhooks?.sms || '',
        callHandling: number.callHandling || 'ai',
        aiVoice: number.aiVoice || 'amy'
        });
        setConfigDialogOpen(true);
    };
    
    if (loading) {
        return (
        <Box display="flex" justifyContent="center" p={3}>
            <CircularProgress />
        </Box>
        );
    }
    
    if (error) {
        return (
        <Alert severity="error" sx={{ mt: 2 }}>
            {error}
        </Alert>
        );
    }
    
    return (
        <Card>
        <CardContent>
            <Typography variant="h6" gutterBottom>
            Your Phone Numbers
            </Typography>
    
            {numbers.length === 0 ? (
            <Alert severity="info">You don't have any phone numbers yet.</Alert>
            ) : (
            <TableContainer component={Paper}>
                <Table>
                <TableHead>
                    <TableRow>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Country</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Monthly Cost</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {numbers.map((number) => (
                    <TableRow key={number.id}>
                        <TableCell>{number.phoneNumber}</TableCell>
                        <TableCell>{number.type}</TableCell>
                        <TableCell>{number.country}</TableCell>
                        <TableCell>
                        <Chip 
                            label={number.status}
                            color={number.status === 'active' ? 'success' : 'default'}
                            size="small"
                        />
                        </TableCell>
                        <TableCell>${number.monthlyPrice}/mo</TableCell>
                        <TableCell>
                        {new Date(number.dateCreated).toLocaleDateString()}
                        </TableCell>
                        <TableCell>
                        <IconButton
                            onClick={() => openConfigDialog(number)}
                            size="small"
                            color="primary"
                            disabled={number.status !== 'active'}
                        >
                            <SettingsIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                            setSelectedNumber(number);
                            setDeleteDialogOpen(true);
                            }}
                            size="small"
                            color="error"
                            disabled={number.status !== 'active'}
                        >
                            <DeleteIcon />
                        </IconButton>
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
            )}
    
            {/* Delete Confirmation Dialog */}
            <Dialog 
            open={deleteDialogOpen} 
            onClose={() => setDeleteDialogOpen(false)}
            >
            <DialogTitle>Release Phone Number?</DialogTitle>
            <DialogContent>
                <Typography>
                Are you sure you want to release the number {selectedNumber?.phoneNumber}? 
                This action cannot be undone.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                <Button 
                onClick={handleDeleteNumber} 
                color="error" 
                variant="contained"
                >
                Release Number
                </Button>
            </DialogActions>
            </Dialog>
    
            {/* Configuration Dialog */}
            <Dialog 
            open={configDialogOpen} 
            onClose={() => setConfigDialogOpen(false)}
            maxWidth="md"
            fullWidth
            >
            <DialogTitle>Configure Phone Number</DialogTitle>
            <DialogContent>
                <Box sx={{ pt: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                    Phone Number: {selectedNumber?.phoneNumber}
                </Typography>
                
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Friendly Name"
                        value={configForm.friendlyName}
                        onChange={(e) => setConfigForm({
                        ...configForm,
                        friendlyName: e.target.value
                        })}
                    />
                    </Grid>
                    
                    <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Voice Webhook URL"
                        value={configForm.voiceWebhook}
                        onChange={(e) => setConfigForm({
                        ...configForm,
                        voiceWebhook: e.target.value
                        })}
                        helperText="URL to handle incoming voice calls"
                    />
                    </Grid>
    
                    <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="SMS Webhook URL"
                        value={configForm.smsWebhook}
                        onChange={(e) => setConfigForm({
                        ...configForm,
                        smsWebhook: e.target.value
                        })}
                        helperText="URL to handle incoming SMS messages"
                    />
                    </Grid>
                    
                    <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>Call Handling</InputLabel>
                        <Select
                        value={configForm.callHandling}
                        onChange={(e) => setConfigForm({
                            ...configForm,
                            callHandling: e.target.value
                        })}
                        label="Call Handling"
                        >
                        <MenuItem value="ai">AI Assistant</MenuItem>
                        <MenuItem value="voicemail">Voicemail</MenuItem>
                        <MenuItem value="forward">Forward Call</MenuItem>
                        </Select>
                    </FormControl>
                    </Grid>
    
                    {configForm.callHandling === 'ai' && (
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                        <InputLabel>AI Voice</InputLabel>
                        <Select
                            value={configForm.aiVoice}
                            onChange={(e) => setConfigForm({
                            ...configForm,
                            aiVoice: e.target.value
                            })}
                            label="AI Voice"
                        >
                            <MenuItem value="amy">Amy (Female)</MenuItem>
                            <MenuItem value="matthew">Matthew (Male)</MenuItem>
                        </Select>
                        </FormControl>
                    </Grid>
                    )}
                </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button 
                onClick={() => setConfigDialogOpen(false)} 
                disabled={configLoading}
                >
                Cancel
                </Button>
                <Button 
                onClick={handleConfigureSave} 
                variant="contained"
                disabled={configLoading}
                >
                {configLoading ? <CircularProgress size={24} /> : 'Save Configuration'}
                </Button>
            </DialogActions>
            </Dialog>
        </CardContent>
        </Card>
    );
    };
    
      

export default PhoneNumbersPage;


