
  import React, { useState, useEffect } from 'react';
  import { collection, addDoc, getDoc, doc, onSnapshot } from 'firebase/firestore';
  import { getAuth } from 'firebase/auth';
  import { db } from '../firebase-config';
  import { Card, CardContent, Typography, Button, Grid, Box, CircularProgress } from '@mui/material';
  
  const VoiceBotSubscription = () => {
    const [loading, setLoading] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const auth = getAuth();
    
    const plans = {
      basic: {
        name: 'Basic',
        monthlyMinutes: 60,
        price: 0.99,
        priceId: 'price_1QMZHsHINCQB1HcDqF39UGxk',
        paymentType: 'subscription',
        features: ['60 Minutes/month', '1 Phone Number', 'Basic AI Features']
      },
      professional: {
        name: 'Professional', 
        monthlyMinutes: 200,
        price: 79.99,
        priceId: 'price_professional_monthly',
        paymentType: 'subscription',
        features: ['200 Minutes/month', '3 Phone Numbers', 'Advanced AI Features']
      },
      enterprise: {
        name: 'Enterprise',
        monthlyMinutes: 500,
        price: 199.99,
        priceId: 'price_enterprise_monthly',
        paymentType: 'subscription',
        features: ['500 Minutes/month', '10 Phone Numbers', 'Premium Support']
      }
    };
  
    useEffect(() => {
      if (auth.currentUser) {
        const unsubscribe = onSnapshot(
          doc(db, 'users', auth.currentUser.uid),
          (doc) => {
            if (doc.exists()) {
              setSubscription(doc.data().subscription);
            }
          }
        );
        return () => unsubscribe();
      }
    }, [auth.currentUser]);
  
    const handleSubscribe = async (plan) => {
      setLoading(true);
      const currentUser = auth.currentUser;
  
      if (!currentUser || !plan.priceId) {
        console.log("Missing user or priceId:", currentUser, plan.priceId);
        setLoading(false);
        return;
      }
  
      const checkoutSessionData = {
        price: plan.priceId,
        allow_promotion_codes: true,
        success_url: `${window.location.origin}/voicebot`,
        trial_period_days: 1,
        cancel_url: `${window.location.origin}/`,
        mode: plan.paymentType === "subscription" ? 'subscription' : 'payment',
        metadata: {
          plan: plan.name.toLowerCase(),
          monthlyMinutes: plan.monthlyMinutes
        }
      };
  
      try {
        const docRef = await addDoc(
          collection(db, `customers/${currentUser.uid}/checkout_sessions`),
          checkoutSessionData
        );
  
        onSnapshot(docRef, (doc) => {
          const { error, url } = doc.data();
          if (error) {
            console.error("Checkout session error:", error);
            setLoading(false);
            return;
          }
          if (url) {
            window.location.assign(url);
          }
        });
      } catch (error) {
        console.error("Error creating checkout session:", error);
        setLoading(false);
      }
    };
  
    return (
      <Box sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Choose Your Plan
        </Typography>
        <Grid container spacing={4}>
          {Object.entries(plans).map(([id, plan]) => (
            <Grid item xs={12} md={4} key={id}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {plan.name}
                  </Typography>
                  <Typography variant="h4">
                    ${plan.price}
                    <Typography variant="subtitle1" component="span">
                      /month
                    </Typography>
                  </Typography>
                  <Box sx={{ my: 2 }}>
                    {plan.features.map((feature, i) => (
                      <Typography key={i} variant="body1">
                        • {feature}
                      </Typography>
                    ))}
                  </Box>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => handleSubscribe(plan)}
                    disabled={loading || (subscription?.plan === plan.name.toLowerCase())}
                  >
                    {loading ? <CircularProgress size={24} /> :
                     subscription?.plan === plan.name.toLowerCase() ? 'Current Plan' : 'Subscribe'}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };
  
  export default VoiceBotSubscription;