


// import React, { useState, useEffect } from 'react';
// import { getAuth, onAuthStateChanged } from 'firebase/auth';
// import {
//   Box,
//   Grid,
//   Card,
//   CardContent,
//   Typography,
//   CircularProgress,
//   Alert,
//   Button
// } from '@mui/material';
// import { toast } from 'react-toastify';
// import SubscriptionCard from './Billing/SubscriptionCard';
// import UsageCard from './Billing/UsageCard';
// import AddCreditsDialog from './Billing/AddCreditsDialog';
// import TransactionHistory from './Billing/TransactionHistory';
// const API_BASE_URL = 'https://app.pandorabot.io';


// const BillingDashboard = () => {
//   const auth = getAuth();
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [billingData, setBillingData] = useState({
//     subscription: null,
//     usage: {
//       records: [],
//       totalMinutesUsed: 0,
//       totalCost: 0
//     },
//     credits: 0,
//     transactions: []
//   });
//   const [showAddCredits, setShowAddCredits] = useState(false);
//   const [user, setUser] = useState(null);

//   useEffect(() => {
//     const auth = getAuth();
//     const unsubscribe = onAuthStateChanged(auth, (user) => {
//       setUser(user);
//       if (user) {
//         loadBillingData(user);
//       }
//     });

//     return () => unsubscribe();
//   }, []);

//   const loadBillingData = async () => {
//     if (!auth.currentUser) return;
//     try {
//       setLoading(true);
      
//       const idToken = await auth.currentUser.getIdToken();
      
//       const response = await fetch(
//         //'http://127.0.0.1:5001/stripe-cde79/us-central1/getBillingDetails',
//         `${API_BASE_URL}/o-getBillingDetails`, 
//         {
//           method: 'POST',
//           headers: {
//             'Authorization': `Bearer ${idToken}`,
//             'Content-Type': 'application/json'
//           },
//           body: JSON.stringify({ userId: auth.currentUser.uid })
//         }
//       );
//       console.log('Billing API response status:', response.status);


//       if (!response.ok) {
//         const errorData = await response.json().catch(() => ({}));
//         throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
//       }

//       const data = await response.json();
//       setBillingData({
//         subscription: data.subscription || null,
//         usage: data.usage || {
//           records: [],
//           totalMinutesUsed: 0,
//           totalCost: 0
//         },
//         credits: data.credits || 0,
//         transactions: data.transactions || []
//       });
//     } catch (err) {
//         console.error('Detailed error:', { // Add detailed error logging
//           message: err.message,
//           stack: err.stack,
//           name: err.name
//         });
//         setError(err.message);
//         toast.error('Failed to load billing details: ' + err.message);
//       } finally {
//       setLoading(false);
//     }
//   };

//   const handleRefresh = () => {
//     if (user) {
//       loadBillingData(user);
//     }
//   };

//   if (loading) {
//     return (
//       <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
//         <CircularProgress />
//       </Box>
//     );
//   }

//   if (error) {
//     return (
//       <Box>
//         <Alert 
//           severity="error" 
//           sx={{ mb: 2 }}
//           action={
//             <Button color="inherit" size="small" onClick={handleRefresh}>
//               Retry
//             </Button>
//           }
//         >
//           {error}
//         </Alert>
//       </Box>
//     );
//   }

//   return (
//     <Box>
//       <Grid container spacing={3}>
//         {/* Subscription Status */}
//         <Grid item xs={12}>
//           <SubscriptionCard 
//             subscription={billingData.subscription}
//             onUpgrade={async (plan) => {
//               try {
//                 const idToken = await user.getIdToken(true);
//                 // Handle subscription upgrade
//               } catch (err) {
//                 toast.error('Failed to upgrade subscription');
//               }
//             }}
//           />
//         </Grid>

//         {/* Usage Summary */}
//         <Grid item xs={12} md={8}>
//           <UsageCard 
//             usage={billingData.usage}
//             subscription={billingData.subscription}
//           />
//         </Grid>

//         {/* Credits Balance */}
//         <Grid item xs={12} md={4}>
//           <Card>
//             <CardContent>
//               <Typography variant="h6" gutterBottom>
//                 Available Credits
//               </Typography>
//               <Typography variant="h3" color="primary" gutterBottom>
//                 ${(billingData.credits || 0).toFixed(2)}
//               </Typography>
//               <Button 
//                 variant="contained" 
//                 fullWidth
//                 onClick={() => setShowAddCredits(true)}
//               >
//                 Add Credits
//               </Button>
//             </CardContent>
//           </Card>
//         </Grid>

//         {/* Transaction History */}
//         <Grid item xs={12}>
//           <TransactionHistory 
//             transactions={billingData.transactions}
//           />
//         </Grid>
//       </Grid>

//       {/* Add Credits Dialog */}
//       <AddCreditsDialog 
//         open={showAddCredits}
//         onClose={() => setShowAddCredits(false)}
//         onSuccess={() => {
//           setShowAddCredits(false);
//           handleRefresh();
//         }}
//         user={user}
//       />
//     </Box>
//   );
// };

// export default BillingDashboard;





import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Alert,
  Button
} from '@mui/material';
import { toast } from 'react-toastify';
import SubscriptionCard from './Billing/SubscriptionCard';
import UsageCard from './Billing/UsageCard';
import AddCreditsDialog from './Billing/AddCreditsDialog';
import TransactionHistory from './Billing/TransactionHistory';
const API_BASE_URL = 'https://app.pandorabot.io';

const BillingDashboard = () => {
  const auth = getAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [billingData, setBillingData] = useState({
    subscription: null,
    balance: {
      total: 0,
      used: 0,
      breakdown: {
        subscription: 0,  
        topUp: 0,
        bonus: 0
      }
    },
    usage: {
      records: [],
      totalCost: 0
    }
  });
//   const [billingData, setBillingData] = useState({
//     subscriptions: {
//       active: null,
//       history: []
//     },
//     billingSummary: {
//       currentPlan: null,
//       status: 'inactive',
//       balance: 0,
//       monthlyMinutes: 0,
//       minutesUsed: 0,
//       nextBillingDate: null,
//       phoneNumbers: {
//         used: 0,
//         limit: 0
//       }
//     },
//     usage: {
//       records: [],
//       totalMinutesUsed: 0,
//       totalCost: 0
//     },
//     transactions: [],
//     lastUpdated: null
//   });
  const [showAddCredits, setShowAddCredits] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (user) {
        loadBillingData(user);
      }
    });

    return () => unsubscribe();
  }, []);

  const loadBillingData = async () => {
    if (!auth.currentUser) return;
    try {
      setLoading(true);
      
      const idToken = await auth.currentUser.getIdToken();
      
      const response = await fetch(
        `${API_BASE_URL}/o-getBillingDetails`, 
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${idToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userId: auth.currentUser.uid })
        }
      );
      console.log('Billing API response status:', response.status);

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data)
      setBillingData(data);
    } catch (err) {
        console.error('Detailed error:', {
          message: err.message,
          stack: err.stack,
          name: err.name
        });
        setError(err.message);
        toast.error('Failed to load billing details: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = () => {
    if (user) {
      loadBillingData(user);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        <Alert 
          severity="error" 
          sx={{ mb: 2 }}
          action={
            <Button color="inherit" size="small" onClick={handleRefresh}>
              Retry
            </Button>
          }
        >
          {error}
        </Alert>
      </Box>
    );
  }

  return (
    <Box>
      <Grid container spacing={3}>
        {/* Subscription Status */}
        <Grid item xs={12}>
          <SubscriptionCard 
            subscription={billingData.subscriptions.active}
            billingSummary={billingData.billingSummary}
            onUpgrade={async (plan) => {
              try {
                const idToken = await user.getIdToken(true);
                // Handle subscription upgrade
              } catch (err) {
                toast.error('Failed to upgrade subscription');
              }
            }}
          />
        </Grid>

        {/* Usage Summary */}
        <Grid item xs={12} md={8}>
          <UsageCard 
            usage={billingData.usage}
            //subscription={billingData.subscriptions.active}
            billingSummary={billingData.billingSummary}
          />
        </Grid>

        {/* Balance/Credits Card */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Available Minutes
              </Typography>
              <Typography variant="h3" color="primary" gutterBottom>
              {billingData.billingSummary.balance || 0}
            </Typography>
              {/* <Typography variant="h3" color="primary" gutterBottom>
                {billingData.billingSummary.balance || 0}
              </Typography> */}
                    <Typography variant="body2" color="textSecondary" gutterBottom>
        {billingData.billingSummary.monthlyMinutes} from subscription
        {billingData.balance?.breakdown?.topUpMinutes > 0 && 
          ` + ${billingData.balance.breakdown.topUpMinutes} from top-up`}
      </Typography>
              <Button 
                variant="contained" 
                fullWidth
                onClick={() => setShowAddCredits(true)}
              >
                Add Minutes
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* Transaction History */}
        <Grid item xs={12}>
          <TransactionHistory 
            transactions={billingData.transactions}
          />
        </Grid>
      </Grid>

      {/* Add Credits Dialog */}
      <AddCreditsDialog 
        open={showAddCredits}
        onClose={() => setShowAddCredits(false)}
        onSuccess={() => {
          setShowAddCredits(false);
          handleRefresh();
        }}
        user={user}
      />
    </Box>
  );
};

export default BillingDashboard;