
// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Card,
//   CardContent,
//   Typography,
//   Button,
//   IconButton,
//   Grid,
//   TextField,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   CircularProgress,
//   Alert,
// } from '@mui/material';
// import { DataGrid } from '@mui/x-data-grid';
// import {
//   PlayArrow as PlayIcon,
//   Stop as StopIcon,
//   Description as TranscriptIcon,
//   Download as DownloadIcon,
// } from '@mui/icons-material';
// import { format } from 'date-fns';
// import { callsService } from './callService';
// import toast from 'react-hot-toast';

// const CallsPage = () => {
//   const [calls, setCalls] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [filters, setFilters] = useState({
//     startDate: '',
//     endDate: '',
//     phoneNumber: '',
//     status: 'all'
//   });
//   const [isPlaying, setIsPlaying] = useState(false);
//   const [selectedCall, setSelectedCall] = useState(null);
//   const [showTranscript, setShowTranscript] = useState(false);
//   const [audioElement, setAudioElement] = useState(null);

//   useEffect(() => {
//     loadCalls();
//   }, []);

//   const loadCalls = async () => {
//     try {
//       setLoading(true);
//       const result = await callsService.getCallHistory(filters);
//       setCalls(result.calls);
//     } catch (error) {
//       toast.error('Failed to load calls');
//       console.error('Error loading calls:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handlePlayRecording = async (call) => {
//     try {
//       if (isPlaying) {
//         audioElement?.pause();
//         setIsPlaying(false);
//         return;
//       }

//       const { recordingUrl } = await callsService.getCallRecording(call.id);
      
//       const audio = new Audio(recordingUrl);
//       audio.onended = () => {
//         setIsPlaying(false);
//         setSelectedCall(null);
//       };
      
//       audio.play();
//       setAudioElement(audio);
//       setIsPlaying(true);
//       setSelectedCall(call);
//     } catch (error) {
//       toast.error('Failed to play recording');
//       console.error('Error playing recording:', error);
//     }
//   };

//   const handleViewTranscript = async (call) => {
//     try {
//       const { transcript } = await callsService.getCallTranscript(call.id);
//       setSelectedCall({ ...call, transcript });
//       setShowTranscript(true);
//     } catch (error) {
//       toast.error('Failed to load transcript');
//       console.error('Error loading transcript:', error);
//     }
//   };

//   const handleFilterChange = (event) => {
//     const { name, value } = event.target;
//     setFilters(prev => ({
//       ...prev,
//       [name]: value
//     }));
//   };

//   const handleApplyFilters = () => {
//     loadCalls();
//   };

//   const columns = [
//     {
//       field: 'timestamp',
//       headerName: 'Date & Time',
//       width: 180,
//       valueGetter: (params) => format(new Date(params.row.timestamp), 'PPpp')
//     },
//     { 
//       field: 'phoneNumber', 
//       headerName: 'Phone Number', 
//       width: 150 
//     },
//     { 
//       field: 'direction', 
//       headerName: 'Direction', 
//       width: 120,
//       renderCell: (params) => (
//         <Typography
//           sx={{
//             color: params.value === 'inbound' ? 'success.main' : 'info.main'
//           }}
//         >
//           {params.value}
//         </Typography>
//       )
//     },
//     { 
//       field: 'duration', 
//       headerName: 'Duration', 
//       width: 120,
//       valueGetter: (params) => {
//         const minutes = Math.floor(params.value / 60);
//         const seconds = params.value % 60;
//         return `${minutes}:${seconds.toString().padStart(2, '0')}`;
//       }
//     },
//     { 
//       field: 'status', 
//       headerName: 'Status', 
//       width: 130,
//       renderCell: (params) => (
//         <Typography
//           sx={{
//             color: params.value === 'completed' ? 'success.main' : 
//                   params.value === 'failed' ? 'error.main' : 'warning.main'
//           }}
//         >
//           {params.value}
//         </Typography>
//       )
//     },
//     {
//       field: 'actions',
//       headerName: 'Actions',
//       width: 180,
//       sortable: false,
//       renderCell: (params) => (
//         <Box>
//           {params.row.recordingUrl && (
//             <IconButton
//               onClick={() => handlePlayRecording(params.row)}
//               color={selectedCall?.id === params.row.id && isPlaying ? 'error' : 'default'}
//             >
//               {selectedCall?.id === params.row.id && isPlaying ? 
//                 <StopIcon /> : <PlayIcon />}
//             </IconButton>
//           )}
//           {params.row.hasTranscript && (
//             <IconButton
//               onClick={() => handleViewTranscript(params.row)}
//             >
//               <TranscriptIcon />
//             </IconButton>
//           )}
//           {params.row.recordingUrl && (
//             <IconButton
//               href={params.row.recordingUrl}
//               download
//             >
//               <DownloadIcon />
//             </IconButton>
//           )}
//         </Box>
//       )
//     }
//   ];

//   return (
//     <Box>
//       <Typography variant="h4" gutterBottom>
//         Call History
//       </Typography>

//       <Card sx={{ mb: 3 }}>
//         <CardContent>
//           <Grid container spacing={2} alignItems="center">
//             <Grid item xs={12} md={3}>
//               <TextField
//                 fullWidth
//                 type="date"
//                 name="startDate"
//                 label="Start Date"
//                 value={filters.startDate}
//                 onChange={handleFilterChange}
//                 InputLabelProps={{ shrink: true }}
//               />
//             </Grid>
//             <Grid item xs={12} md={3}>
//               <TextField
//                 fullWidth
//                 type="date"
//                 name="endDate"
//                 label="End Date"
//                 value={filters.endDate}
//                 onChange={handleFilterChange}
//                 InputLabelProps={{ shrink: true }}
//               />
//             </Grid>
//             <Grid item xs={12} md={2}>
//               <FormControl fullWidth>
//                 <InputLabel>Status</InputLabel>
//                 <Select
//                   name="status"
//                   value={filters.status}
//                   onChange={handleFilterChange}
//                   label="Status"
//                 >
//                   <MenuItem value="all">All</MenuItem>
//                   <MenuItem value="completed">Completed</MenuItem>
//                   <MenuItem value="failed">Failed</MenuItem>
//                   <MenuItem value="busy">Busy</MenuItem>
//                   <MenuItem value="no-answer">No Answer</MenuItem>
//                 </Select>
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} md={3}>
//               <TextField
//                 fullWidth
//                 name="phoneNumber"
//                 label="Phone Number"
//                 value={filters.phoneNumber}
//                 onChange={handleFilterChange}
//               />
//             </Grid>
//             <Grid item xs={12} md={1}>
//               <Button
//                 fullWidth
//                 variant="contained"
//                 onClick={handleApplyFilters}
//               >
//                 Filter
//               </Button>
//             </Grid>
//           </Grid>
//         </CardContent>
//       </Card>

//       <Card>
//         <CardContent>
//           <div style={{ height: 600, width: '100%' }}>
//             <DataGrid
//               rows={calls}
//               columns={columns}
//               pageSize={10}
//               rowsPerPageOptions={[10, 25, 50]}
//               loading={loading}
//               disableSelectionOnClick
//               autoHeight
//             />
//           </div>
//         </CardContent>
//       </Card>

//       {/* Transcript Dialog */}
//       <Dialog
//         open={showTranscript}
//         onClose={() => setShowTranscript(false)}
//         maxWidth="md"
//         fullWidth
//       >
//         <DialogTitle>
//           Call Transcript
//           <Typography variant="subtitle2" color="textSecondary">
//             {selectedCall && format(new Date(selectedCall.timestamp), 'PPpp')}
//           </Typography>
//         </DialogTitle>
//         <DialogContent>
//           {selectedCall?.transcript ? (
//             <Typography whiteSpace="pre-wrap">
//               {selectedCall.transcript}
//             </Typography>
//           ) : (
//             <Alert severity="info">
//               Transcript not available
//             </Alert>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setShowTranscript(false)}>
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default CallsPage;


import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  IconButton,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Alert,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  PlayArrow as PlayIcon,
  Stop as StopIcon,
  Description as TranscriptIcon,
  Download as DownloadIcon,
} from '@mui/icons-material';
import { format } from 'date-fns';
import { getAuth } from 'firebase/auth';
import toast from 'react-hot-toast';

const API_BASE_URL = 'https://app.pandorabot.io';


const CallsPage = () => {
  const [calls, setCalls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    startDate: format(new Date().setDate(new Date().getDate() - 30), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
    phoneNumber: '',
    status: 'all'
  });
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedCall, setSelectedCall] = useState(null);
  const [showTranscript, setShowTranscript] = useState(false);
  const [audioElement, setAudioElement] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [totalCalls, setTotalCalls] = useState(0);


  const loadCalls = async () => {
    try {
      setLoading(true);
      const auth = getAuth();
      const idToken = await auth.currentUser.getIdToken();

      // Build query params
      const params = new URLSearchParams({
        page: page + 1,
        limit: pageSize,
        ...filters
      });

      const response = await fetch(
        `${API_BASE_URL}/o-getCallHistory?${params}`,
        //`http://127.0.0.1:5001/stripe-cde79/us-central1/getCallHistory?${params}`,
        {
          headers: {
            'Authorization': `Bearer ${idToken}`
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to load calls');
      }

      const data = await response.json();
      setCalls(data.calls);
      setTotalCalls(data.total);
    } catch (error) {
      console.error('Error loading calls:', error);
      toast.error('Failed to load calls');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadCalls();
  }, [page, pageSize]);

  const handlePlayRecording = async (call) => {
    try {
      if (isPlaying) {
        audioElement?.pause();
        setIsPlaying(false);
        setSelectedCall(null);
        return;
      }

      const auth = getAuth();
      const idToken = await auth.currentUser.getIdToken();

      const response = await fetch(
        `${API_BASE_URL}/o-getCallRecording?callId=${call.id}`,
        //`http://127.0.0.1:5001/stripe-cde79/us-central1/getCallRecording?callId=${call.id}`,
        {
          headers: {
            'Authorization': `Bearer ${idToken}`
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to get recording');
      }

      const { recordingUrl } = await response.json();
      
      const audio = new Audio(recordingUrl);
      audio.onended = () => {
        setIsPlaying(false);
        setSelectedCall(null);
      };
      
      audio.play();
      setAudioElement(audio);
      setIsPlaying(true);
      setSelectedCall(call);

    } catch (error) {
      console.error('Error playing recording:', error);
      toast.error('Failed to play recording');
    }
  };

  const handleViewTranscript = async (call) => {
    try {
      const auth = getAuth();
      const idToken = await auth.currentUser.getIdToken();

      const response = await fetch(
        `${API_BASE_URL}/o-getCallTranscript?callId=${call.id}`,
        //`http://127.0.0.1:5001/stripe-cde79/us-central1/getCallTranscript?callId=${call.id}`,
        {
          headers: {
            'Authorization': `Bearer ${idToken}`
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to get transcript');
      }

      const { transcript } = await response.json();
      setSelectedCall({ ...call, transcript });
      setShowTranscript(true);
    } catch (error) {
      console.error('Error loading transcript:', error);
      toast.error('Failed to load transcript');
    }
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleApplyFilters = () => {
    setPage(0); // Reset to first page
    loadCalls();
  };

  const columns = [
    {
      field: 'timestamp',
      headerName: 'Date & Time',
      width: 180,
      valueGetter: (params) => format(new Date(params.row.timestamp), 'PPpp')
    },
    { 
      field: 'phoneNumber', 
      headerName: 'To Number', 
      width: 150 
    },
    { 
      field: 'fromNumber', 
      headerName: 'From Number', 
      width: 150 
    },
    { 
      field: 'direction', 
      headerName: 'Direction', 
      width: 120,
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.value === 'inbound' ? 'success.main' : 'info.main'
          }}
        >
          {params.value}
        </Typography>
      )
    },
    { 
      field: 'duration', 
      headerName: 'Duration', 
      width: 120,
      valueGetter: (params) => {
        const minutes = Math.floor(params.value / 60);
        const seconds = params.value % 60;
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
      }
    },
    { 
      field: 'status', 
      headerName: 'Status', 
      width: 130,
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.value === 'completed' ? 'success.main' : 
                  params.value === 'failed' ? 'error.main' : 'warning.main'
          }}
        >
          {params.value}
        </Typography>
      )
    },
    {
      field: 'price',
      headerName: 'Cost',
      width: 100,
      valueGetter: (params) => `${params.row.price} ${params.row.priceUnit}`
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 180,
      sortable: false,
      renderCell: (params) => (
        <Box>
          {params.row.hasRecording && (
            <IconButton
              onClick={() => handlePlayRecording(params.row)}
              color={selectedCall?.id === params.row.id && isPlaying ? 'error' : 'default'}
            >
              {selectedCall?.id === params.row.id && isPlaying ? 
                <StopIcon /> : <PlayIcon />}
            </IconButton>
          )}
          {params.row.hasTranscript && (
            <IconButton
              onClick={() => handleViewTranscript(params.row)}
            >
              <TranscriptIcon />
            </IconButton>
          )}
          {params.row.hasRecording && (
            <IconButton
              href={params.row.recordingUrl}
              download
              disabled={!params.row.recordingUrl}
            >
              <DownloadIcon />
            </IconButton>
          )}
        </Box>
      )
    }
  ];

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Call History
      </Typography>

      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                type="date"
                name="startDate"
                label="Start Date"
                value={filters.startDate}
                onChange={handleFilterChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                type="date"
                name="endDate"
                label="End Date"
                value={filters.endDate}
                onChange={handleFilterChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  name="status"
                  value={filters.status}
                  onChange={handleFilterChange}
                  label="Status"
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="completed">Completed</MenuItem>
                  <MenuItem value="failed">Failed</MenuItem>
                  <MenuItem value="busy">Busy</MenuItem>
                  <MenuItem value="no-answer">No Answer</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                name="phoneNumber"
                label="Phone Number"
                value={filters.phoneNumber}
                onChange={handleFilterChange}
                placeholder="e.g. +1234567890"
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <Button
                fullWidth
                variant="contained"
                onClick={handleApplyFilters}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Filter'}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <div style={{ height: 600, width: '100%' }}>
            <DataGrid
              rows={calls}
              columns={columns}
              pageSize={pageSize}
              page={page}
              rowsPerPageOptions={[10, 25, 50]}
              rowCount={totalCalls}
              pagination
              paginationMode="server"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              loading={loading}
              disableSelectionOnClick
              autoHeight
            />
          </div>
        </CardContent>
      </Card>

      {/* Transcript Dialog */}
      <Dialog
        open={showTranscript}
        onClose={() => setShowTranscript(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Call Transcript
          <Typography variant="subtitle2" color="textSecondary">
            {selectedCall && format(new Date(selectedCall.timestamp), 'PPpp')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {selectedCall?.transcript ? (
            <Typography whiteSpace="pre-wrap">
              {selectedCall.transcript}
            </Typography>
          ) : (
            <Alert severity="info">
              Transcript not available
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowTranscript(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CallsPage;