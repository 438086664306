

// import React, { useState } from 'react';
// import {
//   Card,
//   CardContent,
//   Typography,
//   Grid,
//   LinearProgress,
//   Button,
//   Chip
// } from '@mui/material';
// import { formatCurrency } from '../../utils/formatters';

// // // Subscription plan configurations
// const SUBSCRIPTION_PLANS = {
//   basic: {
//     name: 'Basic',
//     monthlyMinutes: 60,
//     price: 29.99,
//     numberLimit: 1
//   },
//   professional: {
//     name: 'Professional',
//     monthlyMinutes: 200,
//     price: 79.99,
//     numberLimit: 3
//   },
//   enterprise: {
//     name: 'Enterprise',
//     monthlyMinutes: 500,
//     price: 199.99,
//     numberLimit: 10
//   }
// };

// const SubscriptionCard = ({ subscription, onUpgrade }) => {
//   const [loading, setLoading] = useState(false);

//   // Handle missing subscription data
//   if (!subscription) {
//     return (
//       <Card>
//         <CardContent>
//           <Typography variant="h6" color="error">
//             Subscription data not available
//           </Typography>
//         </CardContent>
//       </Card>
//     );
//   }

//   // Get plan details either from subscription or default config
//   const planDetails = subscription.planDetails || SUBSCRIPTION_PLANS[subscription.plan] || SUBSCRIPTION_PLANS.basic;
  
//   // Calculate usage percentages safely
//   const minutesPercentage = (subscription.minutesUsed / planDetails.monthlyMinutes) * 100 || 0;
//   const numbersPercentage = (subscription.phoneNumbersUsed / planDetails.numberLimit) * 100 || 0;
//   const minutesRemaining = planDetails.monthlyMinutes - (subscription.minutesUsed || 0);

//   return (
//     <Card>
//       <CardContent>
//         <Grid container spacing={2}>
//           <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
//             <Typography variant="h6">
//               {planDetails.name} Plan
//             </Typography>
//             <Chip 
//               label={(subscription.status || 'INACTIVE').toUpperCase()}
//               color={subscription.status === 'active' ? 'success' : 'warning'}
//             />
//           </Grid>

//           <Grid item xs={12} md={4}>
//             <Typography variant="subtitle2" color="textSecondary">
//               Minutes Used
//             </Typography>
//             <Typography variant="h5" gutterBottom>
//               {subscription.minutesUsed || 0} / {planDetails.monthlyMinutes}
//             </Typography>
//             <LinearProgress 
//               variant="determinate" 
//               value={Math.min(minutesPercentage, 100)}
//               color={minutesPercentage > 90 ? 'error' : 'primary'}
//               sx={{ height: 8, borderRadius: 4 }}
//             />
//             {minutesRemaining <= (planDetails.monthlyMinutes * 0.1) && (
//               <Typography color="error" variant="caption" sx={{ display: 'block', mt: 1 }}>
//                 Low on minutes! Consider upgrading your plan.
//               </Typography>
//             )}
//           </Grid>

//           <Grid item xs={12} md={4}>
//             <Typography variant="subtitle2" color="textSecondary">
//               Phone Numbers
//             </Typography>
//             <Typography variant="h5" gutterBottom>
//               {subscription.phoneNumbersUsed || 0} / {planDetails.numberLimit}
//             </Typography>
//             <LinearProgress 
//               variant="determinate" 
//               value={Math.min(numbersPercentage, 100)}
//               color={numbersPercentage > 90 ? 'error' : 'primary'}
//               sx={{ height: 8, borderRadius: 4 }}
//             />
//           </Grid>

//           <Grid item xs={12} md={4}>
//             <Typography variant="subtitle2" color="textSecondary">
//               Monthly Cost
//             </Typography>
//             <Typography variant="h5">
//               {formatCurrency(planDetails.price)}
//             </Typography>
//             <Button 
//               variant="contained" 
//               color="primary"
//               fullWidth 
//               sx={{ mt: 2 }}
//               onClick={() => onUpgrade && onUpgrade()}
//               disabled={loading || subscription.plan === 'enterprise'}
//             >
//               {loading ? 'Processing...' : 'Upgrade Plan'}
//             </Button>
//           </Grid>
//         </Grid>
//       </CardContent>
//     </Card>
//   );
// };

// export default SubscriptionCard;

import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  LinearProgress,
  Button,
  Chip
} from '@mui/material';
import { formatCurrency } from '../../utils/formatters';

// Plan configurations
const PLAN_DEFAULTS = {
  basic: {
    monthlyMinutes: 60,
    phoneNumberLimit: 1,
    monthlyCost: 29.99
  },
  professional: {
    monthlyMinutes: 200,
    phoneNumberLimit: 3,
    monthlyCost: 79.99
  },
  enterprise: {
    monthlyMinutes: 500,
    phoneNumberLimit: 10,
    monthlyCost: 199.99
  }
};

const SubscriptionCard = ({ billingSummary }) => {
  const currentPlan = billingSummary?.currentPlan?.toLowerCase() || 'basic';
  const planDefaults = PLAN_DEFAULTS[currentPlan] || PLAN_DEFAULTS.basic;
  
  // Combine defaults with actual data
  const planDetails = {
    monthlyMinutes: billingSummary?.monthlyMinutes || planDefaults.monthlyMinutes,
    phoneNumberLimit: billingSummary?.phoneNumbers?.limit || planDefaults.phoneNumberLimit,
    monthlyCost: billingSummary?.monthlyCost || planDefaults.monthlyCost,
    minutesUsed: billingSummary?.minutesUsed || 0,
    phoneNumbersUsed: billingSummary?.phoneNumbers?.used || 0,
    status: billingSummary?.status || 'inactive'
  };

  // Calculate usage percentages
  const minutesPercentage = (planDetails.minutesUsed / planDetails.monthlyMinutes) * 100;
  const numbersPercentage = (planDetails.phoneNumbersUsed / planDetails.phoneNumberLimit) * 100;
  const minutesRemaining = planDetails.monthlyMinutes - planDetails.minutesUsed;

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">
              {currentPlan.charAt(0).toUpperCase() + currentPlan.slice(1)} Plan
            </Typography>
            <Chip 
              label={planDetails.status.toUpperCase()}
              color={planDetails.status === 'active' ? 'success' : 'warning'}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography variant="subtitle2" color="textSecondary">
              Minutes Used
            </Typography>
            <Typography variant="h5" gutterBottom>
              {planDetails.minutesUsed} / {planDetails.monthlyMinutes}
            </Typography>
            <LinearProgress 
              variant="determinate" 
              value={Math.min(minutesPercentage, 100)}
              color={minutesPercentage > 90 ? 'error' : 'primary'}
              sx={{ height: 8, borderRadius: 4 }}
            />
            {minutesRemaining <= (planDetails.monthlyMinutes * 0.1) && (
              <Typography color="error" variant="caption" sx={{ display: 'block', mt: 1 }}>
                Low on minutes! Consider upgrading your plan.
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography variant="subtitle2" color="textSecondary">
              Phone Numbers
            </Typography>
            <Typography variant="h5" gutterBottom>
              {planDetails.phoneNumbersUsed} / {planDetails.phoneNumberLimit}
            </Typography>
            <LinearProgress 
              variant="determinate" 
              value={Math.min(numbersPercentage, 100)}
              color={numbersPercentage > 90 ? 'error' : 'primary'}
              sx={{ height: 8, borderRadius: 4 }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography variant="subtitle2" color="textSecondary">
              Monthly Cost
            </Typography>
            <Typography variant="h5">
              {formatCurrency(planDetails.monthlyCost)}
            </Typography>
            {billingSummary?.nextBillingDate && (
              <Typography variant="caption" color="textSecondary" display="block">
                Next billing: {new Date(billingSummary.nextBillingDate).toLocaleDateString()}
              </Typography>
            )}
            <Button 
              variant="contained" 
              color="primary"
              fullWidth 
              sx={{ mt: 2 }}
              onClick={() => {}}
              disabled={currentPlan === 'enterprise'}
            >
              Upgrade Plan
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SubscriptionCard;