// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// In your Firebase config file (e.g., firebase-config.js)
import { getFirestore } from "firebase/firestore";
import { getFunctions } from 'firebase/functions';

import 'firebase/functions';
// #const { getFunctionsClient } = require('@google-cloud/functions-framework');


// Initialize Firestore
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyAWH9Qyrnv8C2IANSYmOgE-whHoKyGJ-XE",
//   authDomain: "stripe-cde79.firebaseapp.com",
//   projectId: "stripe-cde79",
//   storageBucket: "stripe-cde79.appspot.com",
//   messagingSenderId: "501471273077",
//   appId: "1:501471273077:web:7c692faac41da32811d83c",
//   measurementId: "G-7DL0JVLW8Q"
// };

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
console.log("Firebase config:", firebaseConfig);

// Initialize Firebase
const app = initializeApp(firebaseConfig);


const db = getFirestore(app);


// const functions1 = getFunctionsClient(app, 'us-central1');
// console.log("Functions initialized:", functions1);


// const functions = getFunctions(app, 'us-central1');
// console.log("Functions initialized:", functions);




const auth = getAuth(app);
console.log("Auth initialized:", auth);
//const functions = getFunctions(app, "us-central1"); // Replace with your function's region if different

export { auth, db };
// const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);

// const auth = getAuth(app);


// export { auth, db , functions };


