
import React, { useState, useEffect,useRef } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import LoadingScreen from '../LoadingScreen';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  FormGroup,
  Paper,
  Slider,
  FormLabel,
  Link,
  Tooltip,
  Grid,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SketchPicker } from 'react-color'; 

const CalendarList = () => {
  const [calendars, setCalendars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [fieldValue, setFieldValue] = useState('Catering Schedule');
  const [appointmentSlotLength, setAppointmentSlotLength] = useState(30);
  const [timeBetweenAppointments, setTimeBetweenAppointments] = useState(0);
  const [showDays, setShowDays] = useState(3);
  const [showSlots, setShowSlots] = useState(5);
  const [calendarChosen, setCalendarChosen] = useState(false);
  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [schedulePriority, setSchedulePriority] = useState('first-available');
  const [appointmentAdvance, setAppointmentAdvance] = useState(0);
  const [calendarTitle, setCalendarTitle] = useState('');
  const [urlParam, setUrlParam] = useState('');
  const [allowAppointmentsUpTo, setAllowAppointmentsUpTo] = useState(365);
  const [showDaysAtATime, setShowDaysAtATime] = useState(3);
  const [showSlotsPerDay, setShowSlotsPerDay] = useState(5);
  const [delayBeforeDisplayed, setDelayBeforeDisplayed] = useState(2);
  const [includeOnLead, setIncludeOnLead] = useState(true);
  const [sendNotification, setSendNotification] = useState(false);
  const [allowVisitorToSkip, setAllowVisitorToSkip] = useState(false);
  const [selectedCalendarTitle, setSelectedCalendarTitle] = useState('');
  const [selectedCalendarTimeZone, setSelectedCalendarTimeZone] = useState('');
  const [selectedCalendarLink, setSelectedCalendarLink] = useState('');
  const [selectedICalLink, setSelectedICalLink] = useState('');
  const [businessDescription, setBusinessDescription] = useState('');
  const [appointmentDays, setAppointmentDays] = useState([]);
  const [appointmentHours, setAppointmentHours] = useState([]);
  const [minAppointmentDuration, setMinAppointmentDuration] = useState(30);
  const [maxAppointmentDuration, setMaxAppointmentDuration] = useState(60);
  const [bufferTimeBefore, setBufferTimeBefore] = useState(0);
  const [bufferTimeAfter, setBufferTimeAfter] = useState(0);
  const [maxAppointmentsPerDay, setMaxAppointmentsPerDay] = useState(10);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [businessId, setBusinessId] = useState('');
  const [botUrl, setBotUrl] = useState('');
  const [showSettings, setShowSettings] = useState(true); // This controls the visibility of the settings form
  const [isCalendarDataReady, setIsCalendarDataReady] = useState(false);
  const [showCopilot, setShowCopilot] = useState(false);
  const [showCustomizationModal, setShowCustomizationModal] = useState(false);
  const [copilotColor, setCopilotColor] = useState("#0000FF");
  const [copilotTextColor, setCopilotTextColor] = useState("#FFFFFF");

  

  const auth = getAuth();
  const navigate = useNavigate();



  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log('User is signed in, fetching token...');
        try {
          const idToken = await user.getIdToken(true);
          console.log('ID Token retrieved:', idToken.substring(0, 20) + '...');
          setUser(user);
  
          // Initiate all fetch requests in parallel
          await Promise.all([
            fetchCalendars(user.uid, idToken),
            fetchSettings(user.uid),
            checkCalendarChosen(user.uid, idToken),
            fetchSelectedCalendarDetails(user.uid, idToken),
          ]);
  
          setIsCalendarDataReady(true);
        } catch (error) {
          console.error('Error during authentication or fetching data:', error);
        } finally {
          setLoading(false);
        }
      } else {
        console.log('No user signed in.');
        setCalendars([]);
        setLoading(false);
        setIsCalendarDataReady(false);
      }
    });
  
    return () => {
      console.log('Cleaning up auth state change listener');
      unsubscribe();
    };
  }, [auth]);
  


  const fetchCalendars = async (userId, idToken) => {
    setLoading(true);
    console.log("Sending request to fetch calendars...");
    try {
      //const response = await fetch('https://us-central1-stripe-cde79.cloudfunctions.net/listCalendars', {
      const response = await fetch('https://app.pandorabot.io/o-listCalendars', {

        method: 'POST',
        mode: "cors",
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({ userId: userId }),
        //credentials: 'include'
      });
      console.log("Response status:", response.status);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      setCalendars(data.calendars);
    } catch (error) {
      console.error('Failed to fetch calendars:', error);
    } finally {
      setLoading(false);
      
    }
  };

  const handleSelectCalendar = async (calendarId) => {
    const idToken = await auth.currentUser.getIdToken();
    const userId = user.uid;
    console.log(userId);
    //const response = await fetch('https://us-central1-stripe-cde79.cloudfunctions.net/saveUserCalendar', {
      const response = await fetch('https://app.pandorabot.io/o-saveUserCalendar', {
      method: 'POST',
      //mode: "cors",
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ userId, calendarId }),
    });
    const result = await response.json();
    if (response.ok) {
      alert('Calendar saved successfully!');
    } else {
      alert('Failed to save calendar: ' + result.error);
    }
  };

  const checkCalendarChosen = async (userId, idToken) => {
    try {
      //const response = await fetch('https://us-central1-stripe-cde79.cloudfunctions.net/checkCalendarChosen', {
      const response = await fetch('https://app.pandorabot.io/o-checkCalendarChosen', {

        method: 'POST',
        mode: "cors",
        //credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify({ userId }),
      
      });
      console.log(response.status)
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      setCalendarChosen(data.calendarChosen);
      setSelectedCalendar(data.selectedCalendar);
    } catch (error) {
      console.error('Failed to check calendar chosen:', error);
    }
  };



  


  const handleUnlinkCalendar = async () => {
    const idToken = await auth.currentUser.getIdToken();
    const userId = user.uid;
    //const response = await fetch('https://us-central1-stripe-cde79.cloudfunctions.net/unlinkCalendar', {
    const response = await fetch('https://app.pandorabot.io/o-unlinkCalendar', {

      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ userId }),
    });
    if (response.ok) {
      setCalendarChosen(false);
      // setSelectedCalendar(null);
      alert('Calendar unlinked successfully!');
    } else {
      alert('Failed to unlink calendar');
    }
  };

  const handleAppointmentDayChange = (day, field, value) => {
    setAppointmentDays((prevDays) => ({
      ...prevDays,
      [day]: {
        ...prevDays[day],
        [field]: value,
      },
    }));
  };

  const handleSaveSettings = async () => {
    const idToken = await auth.currentUser.getIdToken();
    const userId = user.uid;
    //const response = await fetch('https://us-central1-stripe-cde79.cloudfunctions.net/saveSettings', {
    const response = await fetch('https://app.pandorabot.io/o-saveSettings', {

      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({
        userId,
        appointmentAdvance,
        calendarTitle,
        appointmentSlotLength,
        timeBetweenAppointments,
        allowAppointmentsUpTo,
        showDaysAtATime,
        showSlotsPerDay,
        schedulePriority,
        delayBeforeDisplayed,
        includeOnLead,
        sendNotification,
        allowVisitorToSkip,
        businessDescription,
        appointmentDays,
        appointmentHours,
        minAppointmentDuration,
        maxAppointmentDuration,
        bufferTimeBefore,
        bufferTimeAfter,
        maxAppointmentsPerDay,
        appointmentTypes,
      }),
    });
    if (response.ok) {
      alert('Settings saved successfully!');
      setShowSettings(false); // Hide settings after saving
    } else {
      alert('Failed to save settings');
    }
  };



  const fetchSettings = async (userId) => {
    const idToken = await auth.currentUser.getIdToken();
    try {
      const response = await fetch('https://app.pandorabot.io/o-getSettings', {
        method: 'POST',
        mode: "cors",
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ userId }),
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();
      
      setAppointmentAdvance(data.appointmentAdvance || 0);
      setCalendarTitle(data.calendarTitle || '');
      setAppointmentSlotLength(data.appointmentSlotLength || 30);
      setTimeBetweenAppointments(data.timeBetweenAppointments || 0);
      setAllowAppointmentsUpTo(data.allowAppointmentsUpTo || 365);
      setShowDaysAtATime(data.showDaysAtATime || 3);
      setUrlParam(data.businessId || '');
      setShowSlotsPerDay(data.showSlotsPerDay || 5);
      setSchedulePriority(data.schedulePriority || 'first-available');
      setDelayBeforeDisplayed(data.delayBeforeDisplayed || 2);
      setIncludeOnLead(data.includeOnLead || true);
      setSendNotification(data.sendNotification || false);
      setAllowVisitorToSkip(data.allowVisitorToSkip || false);
      setBusinessDescription(data.businessDescription || '');
      setAppointmentDays(data.appointmentDays || {});
      setMinAppointmentDuration(data.minAppointmentDuration || 30);
      setMaxAppointmentDuration(data.maxAppointmentDuration || 60);
      setBufferTimeBefore(data.bufferTimeBefore || 0);
      setBufferTimeAfter(data.bufferTimeAfter || 0);
      setMaxAppointmentsPerDay(data.maxAppointmentsPerDay || 10);
      setAppointmentTypes(data.appointmentTypes || []);
      setAllowAppointmentsUpTo(data.allowAppointmentsUpTo || 365);
      setBusinessId(data.businessId || ''); // Fetch the businessId from the response
      setBotUrl(`https://events.pandorabot.io/?name=${data.businessId}`); // Create the Bot URL
      setShowSettings(data.calendarTitle ? false : true); // If settings exist, hide them by default
    } catch (error) {
      console.error('Failed to fetch settings:', error);
    }
  };

  const toggleShowSettings = () => {
    setShowSettings(!showSettings);
  };



  const fetchSelectedCalendarDetails = async (userId, idToken) => {
    try {
      //const response = await fetch('https://us-central1-stripe-cde79.cloudfunctions.net/getSelectedCalendarDetails', {
      const response = await fetch('https://app.pandorabot.io/o-getSelectedCalendarDetails', {

        method: 'POST',
        mode: "cors",
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ userId }),
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      console.log("data",data)
      setSelectedCalendarTitle(data.calendarTitle);
      setSelectedCalendarTimeZone(data.calendarTimeZone);
      setSelectedCalendarLink(data.calendarLink);
      setSelectedICalLink(data.iCalLink);
    } catch (error) {
      console.error('Failed to fetch selected calendar details:', error);
    }
  };

  const initiateOAuth = () => {
    navigate('/oauth');
  };

  return (
    <>
      {  loading ? (
        <LoadingScreen />
      ) : (
        <Box sx={{ padding: 3 }}>
          <Navbar />
          <Container maxWidth="sm" sx={{ marginTop: 3 }}>
            <Paper elevation={1} sx={{ padding: 3 }}>
              {/* If we are still waiting for calendar data to load */}
              {!isCalendarDataReady && (
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h6">Loading Calendar Data...</Typography>
                  <LoadingScreen />
                </Box>
              )}
  
              {/* If calendar data is ready, but no calendar is chosen */}
              {isCalendarDataReady && !calendarChosen && calendars.length > 0 && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={initiateOAuth}
                    sx={{ marginBottom: 2 }}
                  >
                    Connect Google Calendar
                  </Button>
                  <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 2 }}>
                    Select a Calendar
                  </Typography>
                  <Box>
                    {calendars.map((cal) => (
                      <Box
                        key={cal.id}
                        onClick={() => handleSelectCalendar(cal.id)}
                        sx={{
                          position: 'relative',
                          cursor: 'pointer',
                          padding: '10px',
                          borderBottom: '1px solid #ccc',
                          '&:hover': {
                            backgroundColor: '#f5f5f5',
                          },
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '10px',
                            transform: 'translateY(-50%)',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              transform: 'translateY(-100%)',
                            },
                          }}
                        >
                          {cal.summary}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </>
              )}
  
              {/* If a calendar is chosen and not in settings edit mode */}
              {isCalendarDataReady && calendarChosen && !showSettings && (
                <>
                  <Box marginBottom={2}>
                    <Typography variant="h6" gutterBottom>
                      Selected Calendar
                    </Typography>
                    <Typography variant="subtitle1">{selectedCalendarTitle}</Typography>
                  </Box>
                  <Box marginBottom={2}>
                    <Typography variant="h6" gutterBottom>
                      Calendar Bot URL
                    </Typography>
                    <Typography variant="body2" style={{ wordWrap: 'break-word' }}>
                      <Link href={botUrl} target="_blank" rel="noopener noreferrer">
                        {botUrl}
                      </Link>
                    </Typography>
                  </Box>
                  <Box marginBottom={2}>
                    <Typography variant="subtitle1" gutterBottom>
                      Time Zone
                    </Typography>
                    <Typography variant="body1">{selectedCalendarTimeZone}</Typography>
                  </Box>
                  <Box marginBottom={2}>
                    <Typography variant="subtitle1" gutterBottom>
                      Calendar Link
                    </Typography>
                    <Typography variant="body2" style={{ wordWrap: 'break-word' }}>
                      <Link href={selectedCalendarLink} target="_blank" rel="noopener noreferrer">
                        {selectedCalendarLink}
                      </Link>
                    </Typography>
                  </Box>
                  <Box marginBottom={2}>
                    <Typography variant="subtitle1" gutterBottom>
                      iCal Link
                    </Typography>
                    <Typography variant="body2" style={{ wordWrap: 'break-word' }}>
                      <Link href={selectedICalLink} target="_blank" rel="noopener noreferrer">
                        {selectedICalLink}
                      </Link>
                    </Typography>
                  </Box>

                  {showCopilot && (
                  <Box marginBottom={2}>
                    <Typography variant="h6" gutterBottom>
                      Copilot Embed Code
                    </Typography>
                    <TextField
                      fullWidth
                      multiline
                      rows={5}
                      value={`<!DOCTYPE html>
                                <html>
                                <head>
                                  <meta charset="utf-8" />
                                  <title>Calendar Copilot</title>
                                </head>
                                <body>
                                  <h1>Calendar Copilot</h1>
                                  <script src="https://events.pandorabot.io/copilot/index.js"></script>
                                  
                                  <script src="https://storage.googleapis.com/pandorabot-copilot/copilot.js" 
                                          data-server="https://events.pandorabot.io" 
                                          data-business-id="${businessId}"></script>
                                </body>
                                </html>`}
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>
                )}

                <Box sx={{ display: 'flex', flexDirection: 'grip', gap: 2 }}>
                  <Button variant="contained" color="secondary" onClick={handleUnlinkCalendar}>
                    Unlink Calendar
                  </Button>
                  <Button variant="contained" color="primary" onClick={toggleShowSettings}>
                    Edit Settings
                  </Button>
                  <Button variant="contained" color="secondary" onClick={() => setShowCustomizationModal(true)}>
                    Customize Copilot
                  </Button>
                  <Button
                    variant="contained"
                    color={showCopilot ? "error" : "primary"}
                    onClick={() => setShowCopilot((prev) => !prev)}
                  >
                    {showCopilot ? "Disable Copilot" : "Enable Copilot"}
                  </Button>
                </Box>

                  {/* <Button variant="contained" color="secondary" onClick={handleUnlinkCalendar}>
                    Unlink Calendar
                  </Button>
                  <Button variant="contained" color="primary" onClick={toggleShowSettings} sx={{ marginLeft: 2 }}>
                    Edit Settings
                  </Button> */}

                </>
              )}
  
              {/* If in settings edit mode */}
              {isCalendarDataReady && calendarChosen && showSettings && (
                <>
                  <Box marginBottom={2}>
                    <Typography variant="h6" gutterBottom>
                      Calendar Settings
                    </Typography>
                  </Box>
                  <TextField
                    fullWidth
                    label="Business Description"
                    value={businessDescription}
                    onChange={(e) => setBusinessDescription(e.target.value)}
                    margin="normal"
                    multiline
                    rows={4}
                  />
                  <Typography variant="subtitle1" gutterBottom>
                    Appointment Days
                  </Typography>
                  {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                    <Box key={day} marginBottom={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={appointmentDays[day]?.available || false}
                            onChange={(e) => handleAppointmentDayChange(day, 'available', e.target.checked)}
                          />
                        }
                        label={day}
                      />
                      {appointmentDays[day]?.available && (
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <InputLabel>Start Time</InputLabel>
                              <Select
                                value={appointmentDays[day]?.startTime || ''}
                                onChange={(e) => handleAppointmentDayChange(day, 'startTime', e.target.value)}
                              >
                                {[...Array(24)].map((_, i) => (
                                  <MenuItem key={i} value={`${i}:00`}>
                                    {`${i}:00`}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <InputLabel>End Time</InputLabel>
                              <Select
                                value={appointmentDays[day]?.endTime || ''}
                                onChange={(e) => handleAppointmentDayChange(day, 'endTime', e.target.value)}
                              >
                                {[...Array(24)].map((_, i) => (
                                  <MenuItem key={i} value={`${i}:00`}>
                                    {`${i}:00`}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}
                    </Box>
                  ))}
  
                  <Typography variant="subtitle1" gutterBottom>
                    Appointment Duration
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Minimum"
                        type="number"
                        value={minAppointmentDuration}
                        onChange={(e) => setMinAppointmentDuration(parseInt(e.target.value))}
                        InputProps={{
                          endAdornment: <Typography variant="body2">minutes</Typography>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Maximum"
                        type="number"
                        value={maxAppointmentDuration}
                        onChange={(e) => setMaxAppointmentDuration(parseInt(e.target.value))}
                        InputProps={{
                          endAdornment: <Typography variant="body2">minutes</Typography>,
                        }}
                      />
                    </Grid>
                  </Grid>
  
                  <Typography variant="subtitle1" gutterBottom>
                    Buffer Time
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Before Appointment"
                        type="number"
                        value={bufferTimeBefore}
                        onChange={(e) => setBufferTimeBefore(parseInt(e.target.value))}
                        InputProps={{
                          endAdornment: <Typography variant="body2">minutes</Typography>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="After Appointment"
                        type="number"
                        value={bufferTimeAfter}
                        onChange={(e) => setBufferTimeAfter(parseInt(e.target.value))}
                        InputProps={{
                          endAdornment: <Typography variant="body2">minutes</Typography>,
                        }}
                      />
                    </Grid>
                  </Grid>
  
                  <TextField
                    fullWidth
                    label="Max Appointments per Day"
                    type="number"
                    value={maxAppointmentsPerDay}
                    onChange={(e) => setMaxAppointmentsPerDay(parseInt(e.target.value))}
                    margin="normal"
                  />
  
                  {/* Additional settings fields as needed */}

                  <Typography variant="subtitle1" gutterBottom>
                Appointment Types
              </Typography>
              {appointmentTypes.map((type, index) => (
                <Box key={index} display="flex" alignItems="center" marginBottom={1}>
                  <TextField
                    fullWidth
                    label="Type"
                    value={type}
                    onChange={(e) => {
                      const updatedTypes = [...appointmentTypes];
                      updatedTypes[index] = e.target.value;
                      setAppointmentTypes(updatedTypes);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setAppointmentTypes(appointmentTypes.filter((_, i) => i !== index))}
                    sx={{ marginLeft: 1 }}
                  >
                    Remove
                  </Button>
                </Box>
              ))}
              <Button
                variant="contained"
                color="primary"
                onClick={() => setAppointmentTypes([...appointmentTypes, ''])}
                sx={{ marginTop: 1 }}
              >
                Add Appointment Type
              </Button>
  
              <TextField
                fullWidth
                label="Calendar Title"
                value={calendarTitle}
                onChange={(e) => setCalendarTitle(e.target.value)}
                margin="normal"
              />
  
              <RadioGroup
                row
                name="position"
                value={appointmentAdvance}
                onChange={(e) => setAppointmentAdvance(parseInt(e.target.value))}
              >
                <FormControlLabel value={0} control={<Radio color="primary" />} label="Immediate" />
                <FormControlLabel value={0} control={<Radio color="primary" />} label="0 hours in advance" />
              </RadioGroup>
  
              <Typography variant="subtitle1" gutterBottom>
                Appointment Slot Settings
              </Typography>
              <TextField
                fullWidth
                label="Appointment slot length"
                type="number"
                value={appointmentSlotLength}
                onChange={(e) => setAppointmentSlotLength(parseInt(e.target.value))}
                margin="normal"
                InputProps={{
                  endAdornment: <Typography variant="body2">minutes</Typography>,
                }}
              />
              <TextField
                fullWidth
                label="Time between appointments"
                type="number"
                value={timeBetweenAppointments}
                onChange={(e) => setTimeBetweenAppointments(parseInt(e.target.value))}
                margin="normal"
                InputProps={{
                  endAdornment: <Typography variant="body2">minutes</Typography>,
                }}
              />
              <TextField
                fullWidth
                label="Allow appointments up to"
                type="number"
                value={allowAppointmentsUpTo}
                onChange={(e) => setAllowAppointmentsUpTo(parseInt(e.target.value))}
                margin="normal"
                InputProps={{
                  endAdornment: <Typography variant="body2">days in advance</Typography>,
                }}
              />
  
              <Typography variant="subtitle1" gutterBottom>
                Presentation Settings
              </Typography>
              <Box display="flex" alignItems="center" marginBottom={2}>
                <Typography variant="body1" marginRight={1}>
                  Show
                </Typography>
                <Select
                  value={showDaysAtATime}
                  onChange={(e) => setShowDaysAtATime(e.target.value)}
                  sx={{ marginRight: 1 }}
                >
                  <MenuItem value={3}>3 Days</MenuItem>
                  {/* Add more options as needed */}
                </Select>
                <Typography variant="body1" marginRight={1}>
                  at a time.
                </Typography>
              </Box>
  
              <Box display="flex" alignItems="center" marginBottom={2}>
                <Typography variant="body1" marginRight={1}>
                  Show up to
                </Typography>
                <Select
                  value={showSlotsPerDay}
                  onChange={(e) => setShowSlotsPerDay(e.target.value)}
                  sx={{ marginRight: 1 }}
                >
                  <MenuItem value={5}>5 Slots</MenuItem>
                  {/* Add more options as needed */}
                </Select>
                <Typography variant="body1" marginRight={1}>
                  per day.
                </Typography>
              </Box>
  
              <Box display="flex" alignItems="center" marginBottom={2}>
                <Typography variant="body1" marginRight={1}>
                  Schedule priority
                </Typography>
                <Select
                  value={schedulePriority}
                  onChange={(e) => setSchedulePriority(e.target.value)}
                >
                  <MenuItem value="first-available">First Available Appointments</MenuItem>
                  {/* Add more options as needed */}
                </Select>
              </Box>
  
              <Typography variant="subtitle1" gutterBottom>
                Advanced Options
              </Typography>
              <TextField
                fullWidth
                label="Delay before this item is displayed"
                type="number"
                value={delayBeforeDisplayed}
                onChange={(e) => setDelayBeforeDisplayed(parseInt(e.target.value))}
                margin="normal"
                InputProps={{
                  endAdornment: <Typography variant="body2">seconds</Typography>,
                }}
              />
              <FormControlLabel
                control={<Checkbox checked={includeOnLead} onChange={(e) => setIncludeOnLead(e.target.checked)} />}
                label="Include on lead"
              />
              <FormControlLabel
                control={<Checkbox checked={sendNotification} onChange={(e) => setSendNotification(e.target.checked)} />}
                label="Send notification"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={allowVisitorToSkip} onChange={(e) => setAllowVisitorToSkip(e.target.checked)} />
                }
                label="Allow Visitor to Skip"
              />
  
                  <Button variant="contained" color="primary" onClick={handleSaveSettings} sx={{ marginTop: 2 }}>
                    Save Settings
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={toggleShowSettings}
                    sx={{ marginTop: 2, marginLeft: 2 }}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Paper>
          </Container>


        {/* Customization Modal for Copilot */}
        <Dialog open={showCustomizationModal} onClose={() => setShowCustomizationModal(false)}>
          <DialogTitle>Customize Copilot</DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1">Widget Background Color</Typography>
            <SketchPicker
              color={copilotColor}
              onChangeComplete={(color) => setCopilotColor(color.hex)}
            />
            <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
              Text Color
            </Typography>
            <SketchPicker
              color={copilotTextColor}
              onChangeComplete={(color) => setCopilotTextColor(color.hex)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowCustomizationModal(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => setShowCustomizationModal(false)} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        {/* Copilot Live Preview Pane */}
        {showCopilot && (
          <Box
            sx={{
              position: 'fixed',
              top: '40%',
              right: '20px',
              transform: 'translateY(-50%)',
              width: '400px',
              height: '600px',
              backgroundColor: '#f5f5f5',
              border: '1px solid #ccc',
              boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
              display: {
                xs: 'none', // Hide on extra-small screens
                sm: 'none', // Hide on small screens
                md: 'block', // Show from medium screens and up
              },
            }}
          >
            <Typography variant="h6" align="center" sx={{ paddingTop: 1 }}>
              Copilot Preview
            </Typography>
            <iframe
              title="Copilot Preview"
              src={`https://events.pandorabot.io/?name=${businessId || ''}`}
              style={{ width: '100%', height: '90%', border: 'none' }}
            ></iframe>
          </Box>
        )}

          {/* Copilot Live Preview Pane */}
          {/* {showCopilot && (
            <Box
              id="copilot-preview-container"
              ref={copilotContainerRef}
              sx={{
                position: 'fixed',
                top: '20%',
                right: 0,
                width: '400px',
                height: '600px',
                backgroundColor: copilotColor,
                color: copilotTextColor,
                border: '1px solid #ccc',
                boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
                padding: 2,
              }}
            >
              <Typography variant="h6" align="center">
                Copilot Preview
              </Typography>
            </Box>
          )} */}





        </Box>
      )}
    </>
  );
  
};

export default CalendarList;


    