import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Chip,
  IconButton,
  Collapse,
  Box,
  Paper
} from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import { formatCurrency } from '../../utils/formatters';

// Transaction Row Component
const TransactionRow = ({ transaction }) => {
  const [open, setOpen] = useState(false);

  const getTypeColor = (type) => {
    switch (type) {
      case 'credit_purchase':
        return 'success';
      case 'subscription_charge':
        return 'primary';
      case 'usage':
        return 'warning';
      default:
        return 'default';
    }
  };

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell padding="checkbox">
          {transaction.type === 'usage' && (
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>
          {new Date(transaction.timestamp).toLocaleString()}
        </TableCell>
        <TableCell>
          <Chip
            label={transaction.type.replace('_', ' ').toUpperCase()}
            color={getTypeColor(transaction.type)}
            size="small"
          />
        </TableCell>
        <TableCell>{transaction.description}</TableCell>
        <TableCell align="right">
          <Typography
            color={transaction.amount >= 0 ? 'success.main' : 'error.main'}
            component="span"
          >
            {transaction.amount >= 0 ? '+' : '-'} {formatCurrency(Math.abs(transaction.amount))}
          </Typography>
        </TableCell>
      </TableRow>

      {/* Expandable Details Row */}
      {transaction.type === 'usage' && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Call Details
                </Typography>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">Duration</TableCell>
                      <TableCell>{formatDuration(transaction.metadata.duration)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">Base Call Cost</TableCell>
                      <TableCell>{formatCurrency(transaction.metadata.baseCallCost)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">AI Processing Cost</TableCell>
                      <TableCell>{formatCurrency(transaction.metadata.aiCost)}</TableCell>
                    </TableRow>
                    {transaction.metadata.transcriptionCost > 0 && (
                      <TableRow>
                        <TableCell component="th" scope="row">Transcription Cost</TableCell>
                        <TableCell>{formatCurrency(transaction.metadata.transcriptionCost)}</TableCell>
                      </TableRow>
                    )}
                    {transaction.metadata.recordingCost > 0 && (
                      <TableRow>
                        <TableCell component="th" scope="row">Recording Cost</TableCell>
                        <TableCell>{formatCurrency(transaction.metadata.recordingCost)}</TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell component="th" scope="row">Total Cost</TableCell>
                      <TableCell>{formatCurrency(transaction.metadata.totalCost)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

// Main Transaction History Component
const TransactionHistory = ({ transactions }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Transaction History
        </Typography>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                <TableCell>Date</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Description</TableCell>
                <TableCell align="right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((transaction) => (
                  <TransactionRow key={transaction.id} transaction={transaction} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={transactions.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CardContent>
    </Card>
  );
};

export default TransactionHistory;