// src/utils/formatters.js

/**
 * Formats a duration in seconds to a human-readable string
 * @param {number} seconds - Duration in seconds
 * @returns {string} Formatted duration string (e.g., "1h 23m 45s")
 */
export const formatDuration = (seconds) => {
    if (!seconds) return '0s';
    
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
  
    const parts = [];
    if (hours > 0) parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes}m`);
    if (remainingSeconds > 0 || parts.length === 0) parts.push(`${remainingSeconds}s`);
  
    return parts.join(' ');
  };
  
  /**
   * Formats a phone number to a standardized format
   * @param {string} phoneNumber - Raw phone number string
   * @param {string} [country='US'] - Country code for formatting
   * @returns {string} Formatted phone number
   */
  export const formatPhoneNumber = (phoneNumber, country = 'US') => {
    if (!phoneNumber) return '';
  
    // Remove all non-digit characters
    const cleaned = phoneNumber.replace(/\D/g, '');
  
    // Format based on country
    switch (country) {
      case 'US':
      case 'CA':
        if (cleaned.length === 10) {
          return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
        } else if (cleaned.length === 11) {
          return `+${cleaned[0]} (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7)}`;
        }
        break;
      case 'GB':
        if (cleaned.length === 11) {
          return `+44 ${cleaned.slice(1, 4)} ${cleaned.slice(4, 7)} ${cleaned.slice(7)}`;
        }
        break;
      // Add more country formats as needed
    }
  
    // Return with basic + prefix if no specific formatting matches
    return phoneNumber.startsWith('+') ? phoneNumber : `+${cleaned}`;
  };
  
  /**
   * Formats a currency amount
   * @param {number} amount - Amount to format
   * @param {string} [currency='USD'] - Currency code
   * @returns {string} Formatted currency string
   */
  export const formatCurrency = (amount, currency = 'USD') => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  };
  
  /**
   * Formats a file size in bytes to human readable format
   * @param {number} bytes - Size in bytes
   * @returns {string} Formatted size string (e.g., "1.5 MB")
   */
  export const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
  
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
  
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
  };
  
  /**
   * Formats a date string to relative time (e.g., "2 hours ago")
   * @param {string|Date} date - Date to format
   * @returns {string} Relative time string
   */
  export const formatRelativeTime = (date) => {
    const now = new Date();
    const diff = now - (typeof date === 'string' ? new Date(date) : date);
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
  
    if (days > 7) {
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }).format(date);
    }
    
    if (days > 0) return `${days}d ago`;
    if (hours > 0) return `${hours}h ago`;
    if (minutes > 0) return `${minutes}m ago`;
    return 'just now';
  };
  
  /**
   * Truncates text with ellipsis
   * @param {string} text - Text to truncate
   * @param {number} [length=50] - Maximum length before truncation
   * @returns {string} Truncated text
   */
  export const truncateText = (text, length = 50) => {
    if (!text || text.length <= length) return text;
    return `${text.slice(0, length)}...`;
  };
  
  /**
   * Formats a status string to a standardized display format
   * @param {string} status - Raw status string
   * @returns {string} Formatted status string
   */
  export const formatStatus = (status) => {
    if (!status) return '';
    
    // Convert snake_case or kebab-case to Title Case
    return status
      .toLowerCase()
      .replace(/[_-]/g, ' ')
      .replace(/\b\w/g, letter => letter.toUpperCase());
  };
  
  /**
   * Formats a boolean value to a user-friendly string
   * @param {boolean} value - Boolean value to format
   * @param {Object} [options] - Formatting options
   * @returns {string} Formatted string
   */
  export const formatBoolean = (value, options = {}) => {
    const defaults = {
      trueText: 'Yes',
      falseText: 'No',
      nullText: 'N/A'
    };
    const opts = { ...defaults, ...options };
    
    if (value === null || value === undefined) return opts.nullText;
    return value ? opts.trueText : opts.falseText;
  };
  
  /**
   * Formats an array of items into a comma-separated string
   * @param {Array} items - Array of items to format
   * @param {Object} [options] - Formatting options
   * @returns {string} Formatted string
   */
  export const formatList = (items, options = {}) => {
    const defaults = {
      maxItems: 3,
      overflow: 'count', // 'count' or 'ellipsis'
      separator: ', '
    };
    const opts = { ...defaults, ...options };
    
    if (!Array.isArray(items) || items.length === 0) return '';
    
    if (items.length <= opts.maxItems) {
      return items.join(opts.separator);
    }
    
    const visibleItems = items.slice(0, opts.maxItems);
    const remaining = items.length - opts.maxItems;
    
    if (opts.overflow === 'count') {
      return `${visibleItems.join(opts.separator)} and ${remaining} more`;
    }
    
    return `${visibleItems.join(opts.separator)}...`;
  };