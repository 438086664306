import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Button,
  Divider,
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormHelperText,
  Tabs,
  Tab,
  Chip
} from '@mui/material';
import {
  Business as BusinessIcon,
  Person as PersonIcon,
  Upload as UploadIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import toast from 'react-hot-toast';
import { getAuth } from 'firebase/auth';



function TabPanel({ children, value, index, ...other }) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`account-tabpanel-${index}`}
        aria-labelledby={`account-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  const AccountPage = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [loading, setLoading] = useState(true);
    const auth = getAuth();
    const [profile, setProfile] = useState({
        personal: {
          firstName: '',
          lastName: '',
          email: '',
          phone: ''
        },
        business: {
          name: '',
          registrationNumber: '',
          vatNumber: '',
          type: 'limited',
          industry: 'technology'
        },
        address: {
          street: '',
          city: '',
          state: '',
          postalCode: '',
          country: 'GB'
        }
      });

  
    useEffect(() => {
      fetchProfileData();
    }, []);


    const fetchProfileData = async () => {
      if (!auth.currentUser) {
        console.log('No user logged in');
        return;
      }
      
      try {
        setLoading(true);
        
        const idToken = await auth.currentUser.getIdToken(true);
        const userId = auth.currentUser.uid;

        console.log('Fetching profile for user:', idToken);

        
        
        console.log('Fetching profile for user:', userId);
        
        const response = await fetch('https://app.pandorabot.io/o-getProfile', {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          },
          body: JSON.stringify({ 
            userId 
          })
        });

        const data = await response.json();
        console.log('Profile data received:', data);
        setProfile(data);
        
      } catch (err) {
        console.error('Error fetching profile:', err);
        toast.error('Failed to load profile data: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    
    // const fetchProfileData = async () => {
    //     if (!auth.currentUser) return;
    //     try {
    //       setLoading(true);
          
    //       const idToken = await auth.currentUser.getIdToken();
    //       console.log(idToken)

          
    //       const response = await fetch(
    //         //'http://127.0.0.1:5001/stripe-cde79/us-central1/getProfile',
    //         'https://app.pandorabot.io/o-getProfile',
    //         {
    //           method: 'GET',
    //           headers: {
    //             'Authorization': `Bearer ${idToken}`,
    //             'Content-Type': 'application/json'
    //           }
    //         }
    //       );
    //       console.log(response)
      
    //       if (!response.ok) {
    //         throw new Error('Failed to fetch profile');
    //       }
      
    //       const data = await response.json();
    //       setProfile(data);
    //     } catch (err) {
    //       toast.error('Failed to load profile data');
    //       console.error(err);
    //     } finally {
    //       setLoading(false);
    //     }
    //   };

    
      

    const handleProfileUpdate = async (updatedData) => {
      try {
        const auth = getAuth();
        const idToken = await auth.currentUser.getIdToken(true);
        console.log(idToken)

        
        //const response = await fetch('http://127.0.0.1:5001/stripe-cde79/us-central1/updateProfile', {
        const response = await fetch('https://app.pandorabot.io/o-updateProfile', {



            method: 'POST',
            headers: {
              'Authorization': `Bearer ${idToken}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              userId: auth.currentUser.uid,
              ...updatedData
            })

            
          }
        );
        console.log(response)
        if (!response.ok) {
          throw new Error('Failed to update profile');
        }
    
        toast.success('Profile updated successfully');
        await fetchProfileData();
      } catch (err) {
        toast.error('Failed to update profile');
        console.error(err);
      }
    };
  
  
    const handleTabChange = (event, newValue) => {
      setActiveTab(newValue);
    };
  
    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
          <CircularProgress />
        </Box>
      );
    }
  
    return (
      <Box>
        <Typography variant="h4" gutterBottom>
          Account Settings
        </Typography>
  
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs 
              value={activeTab} 
              onChange={handleTabChange}
              aria-label="account settings tabs"
            >
              <Tab 
                icon={<PersonIcon />} 
                iconPosition="start" 
                label="Profile" 
                id="account-tab-0"
              />
              <Tab 
                icon={<BusinessIcon />} 
                iconPosition="start" 
                label="Business Details" 
                id="account-tab-1"
              />
              <Tab 
                icon={<UploadIcon />} 
                iconPosition="start" 
                label="Documents" 
                id="account-tab-2"
              />
            </Tabs>
          </Box>
  
          <TabPanel value={activeTab} index={0}>
            <PersonalInfoForm 
              data={profile?.personal || {}}
              address={profile?.address || {}}
              onSave={handleProfileUpdate}
            />
          </TabPanel>
  
          <TabPanel value={activeTab} index={1}>
            <BusinessInfoForm 
              data={profile?.business || {}}
              onSave={handleProfileUpdate}
            />
          </TabPanel>
  
          <TabPanel value={activeTab} index={2}>
            <DocumentsManager 
              documents={profile?.documents || []}
              onUpload={async (file, type) => {
                // TODO: Implement document upload
                toast.success('Document uploaded successfully');
              }}
              onDelete={async (documentId) => {
                // TODO: Implement document delete
                toast.success('Document deleted successfully');
              }}
            />
          </TabPanel>
        </Box>
      </Box>
    );
  };
  
  
// const AccountPage = () => {
// const [activeTab, setActiveTab] = useState(0);
// const [loading, setLoading] = useState(true);
// const [profile, setProfile] = useState(null);
// const functions = getFunctions();


// useEffect(() => {
//     fetchProfileData();
// }, []);
// const fetchProfileData = async () => {
//     try {
//       setLoading(true);
//       const getProfile = httpsCallable(functions, 'getProfile');
//       const result = await getProfile();
//       setProfile(result.data);
//     } catch (err) {
//       toast.error('Failed to load profile data');
//       console.error(err);
//     } finally {
//       setLoading(false);
//     }
//   };

// // const fetchProfileData = async () => {
// //     try {
// //     // TODO: Implement API call
// //     await new Promise(resolve => setTimeout(resolve, 1000));
// //     setProfile({
// //         personal: {
// //         firstName: 'John',
// //         lastName: 'Doe',
// //         email: 'john@example.com',
// //         phone: '+1234567890',
// //         },
// //         business: {
// //         name: 'Acme Corp',
// //         registrationNumber: '12345678',
// //         vatNumber: 'GB123456789',
// //         type: 'limited',
// //         industry: 'technology',
// //         },
// //         address: {
// //         street: '123 Business St',
// //         city: 'London',
// //         state: 'Greater London',
// //         postalCode: 'SW1A 1AA',
// //         country: 'GB',
// //         },
// //         documents: [
// //         {
// //             id: '1',
// //             type: 'business_registration',
// //             name: 'Business Registration.pdf',
// //             status: 'verified',
// //             uploadDate: '2023-11-15',
// //         },
// //         {
// //             id: '2',
// //             type: 'proof_of_address',
// //             name: 'Utility Bill.pdf',
// //             status: 'pending',
// //             uploadDate: '2023-11-15',
// //         },
// //         ],
// //     });
// //     } catch (err) {
// //     toast.error('Failed to load profile data');
// //     } finally {
// //     setLoading(false);
// //     }
// // };

// const handleTabChange = (event, newValue) => {
//     setActiveTab(newValue);
// };

// if (loading) {
//     return (
//     <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
//         <CircularProgress />
//     </Box>
//     );
// }

// return (
//     <Box>
//     <Typography variant="h4" gutterBottom>
//         Account Settings
//     </Typography>

//     <Box sx={{ width: '100%' }}>
//         <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//         <Tabs 
//             value={activeTab} 
//             onChange={handleTabChange}
//             aria-label="account settings tabs"
//         >
//             <Tab 
//             icon={<PersonIcon />} 
//             iconPosition="start" 
//             label="Profile" 
//             id="account-tab-0"
//             />
//             <Tab 
//             icon={<BusinessIcon />} 
//             iconPosition="start" 
//             label="Business Details" 
//             id="account-tab-1"
//             />
//             <Tab 
//             icon={<UploadIcon />} 
//             iconPosition="start" 
//             label="Documents" 
//             id="account-tab-2"
//             />
//         </Tabs>
//         </Box>

//         <TabPanel value={activeTab} index={0}>
//         <PersonalInfoForm 
//             data={profile.personal} 
//             address={profile.address}
//             onSave={async (data) => {
//             // TODO: Implement save
//             toast.success('Profile updated successfully');
//             }}
//         />
//         </TabPanel>

//         <TabPanel value={activeTab} index={1}>
//         <BusinessInfoForm 
//             data={profile.business}
//             onSave={async (data) => {
//             // TODO: Implement save
//             toast.success('Business details updated successfully');
//             }}
//         />
//         </TabPanel>

//         <TabPanel value={activeTab} index={2}>
//         <DocumentsManager 
//             documents={profile.documents}
//             onUpload={async (file, type) => {
//             // TODO: Implement upload
//             toast.success('Document uploaded successfully');
//             }}
//             onDelete={async (documentId) => {
//             // TODO: Implement delete
//             toast.success('Document deleted successfully');
//             }}
//         />
//         </TabPanel>
//     </Box>
//     </Box>
// );
// };

const PersonalInfoForm = ({ data, address, onSave }) => {
  const [formData, setFormData] = useState({
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        email: data.email || '',
        phone: data.phone || ''
        });

        const [addressData, setAddressData] = useState({
        street: address.street || '',
        city: address.city || '',
        state: address.state || '',
        postalCode: address.postalCode || '',
        country: address.country || 'GB'
        });
    
  const [saving, setSaving] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    try {
      await onSave({
        personal: formData,
        address: addressData
      });
    } catch (err) {
      console.error(err);
    } finally {
      setSaving(false);
    }
  };


//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setSaving(true);
//     try {
//       await onSave({ ...formData, address: addressData });
//     } finally {
//       setSaving(false);
//     }
//   };

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardHeader title="Personal Information" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                value={formData.firstName || ''}
                onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                value={formData.lastName || ''}
                onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                type="email"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Phone Number"
                value={formData.phone}
                onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                required
              />
            </Grid>
          </Grid>

          <Divider sx={{ my: 3 }} />

          <Typography variant="h6" gutterBottom>
            Address
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Street Address"
                value={addressData.street}
                onChange={(e) => setAddressData({ ...addressData, street: e.target.value })}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="City"
                value={addressData.city}
                onChange={(e) => setAddressData({ ...addressData, city: e.target.value })}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="State/Province"
                value={addressData.state}
                onChange={(e) => setAddressData({ ...addressData, state: e.target.value })}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Postal Code"
                value={addressData.postalCode}
                onChange={(e) => setAddressData({ ...addressData, postalCode: e.target.value })}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel>Country</InputLabel>
                <Select
                  value={addressData.country}
                  onChange={(e) => setAddressData({ ...addressData, country: e.target.value })}
                  label="Country"
                >
                  <MenuItem value="GB">United Kingdom</MenuItem>
                  <MenuItem value="US">United States</MenuItem>
                  <MenuItem value="CA">Canada</MenuItem>
                  {/* Add more countries */}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>

        <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            type="submit"
            variant="contained"
            disabled={saving}
            startIcon={saving ? <CircularProgress size={20} /> : <SaveIcon />}
          >
            Save Changes
          </Button>
        </Box>
      </Card>
    </form>
  );
};

// Continue from previous file...

const BusinessInfoForm = ({ data, onSave }) => {
    const [formData, setFormData] = useState({
        name: data?.name || '',
        registrationNumber: data?.registrationNumber || '',
        vatNumber: data?.vatNumber || '',
        type: data?.type || 'limited',
        industry: data?.industry || 'technology'
      });
    const [saving, setSaving] = useState(false);
  
    const businessTypes = [
      { value: 'limited', label: 'Limited Company' },
      { value: 'sole_proprietorship', label: 'Sole Proprietorship' },
      { value: 'partnership', label: 'Partnership' },
      { value: 'llc', label: 'LLC' },
    ];
  
    const industries = [
      { value: 'technology', label: 'Technology' },
      { value: 'finance', label: 'Finance' },
      { value: 'healthcare', label: 'Healthcare' },
      { value: 'retail', label: 'Retail' },
      { value: 'manufacturing', label: 'Manufacturing' },
      { value: 'other', label: 'Other' },
    ];
  
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSaving(true);
        try {
          await onSave({
            business: formData  // Only send business data
          });
        } catch (err) {
          console.error(err);
        } finally {
          setSaving(false);
        }
      };
  
    return (
      <form onSubmit={handleSubmit}>
        <Card>
          <CardHeader 
            title="Business Information"
            subheader="This information is required for regulatory compliance"
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Business Name"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  required
                  helperText="Enter the registered business name"
                />
              </Grid>
  
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Registration Number"
                  value={formData.registrationNumber}
                  onChange={(e) => setFormData({ ...formData, registrationNumber: e.target.value })}
                  required
                  helperText="Companies House registration number"
                />
              </Grid>
  
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="VAT Number"
                  value={formData.vatNumber}
                  onChange={(e) => setFormData({ ...formData, vatNumber: e.target.value })}
                  helperText="Optional if registered for VAT"
                />
              </Grid>
  
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required>
                  <InputLabel>Business Type</InputLabel>
                  <Select
                    value={formData.type}
                    onChange={(e) => setFormData({ ...formData, type: e.target.value })}
                    label="Business Type"
                  >
                    {businessTypes.map(type => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    Select your business structure
                  </FormHelperText>
                </FormControl>
              </Grid>
  
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required>
                  <InputLabel>Industry</InputLabel>
                  <Select
                    value={formData.industry}
                    onChange={(e) => setFormData({ ...formData, industry: e.target.value })}
                    label="Industry"
                  >
                    {industries.map(industry => (
                      <MenuItem key={industry.value} value={industry.value}>
                        {industry.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    Select your primary industry
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
  
          <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="submit"
              variant="contained"
              disabled={saving}
              startIcon={saving ? <CircularProgress size={20} /> : <SaveIcon />}
            >
              Save Changes
            </Button>
          </Box>
        </Card>
      </form>
    );
  };
  
  const DocumentsManager = ({ documents, onUpload, onDelete }) => {
    const [uploadDialog, setUploadDialog] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [documentType, setDocumentType] = useState('');
    const [uploading, setUploading] = useState(false);
  
    const documentTypes = [
      { 
        value: 'business_registration', 
        label: 'Business Registration',
        description: 'Official registration document from Companies House'
      },
      { 
        value: 'proof_of_address', 
        label: 'Proof of Address',
        description: 'Utility bill or bank statement from the last 3 months'
      },
      { 
        value: 'id_document', 
        label: 'ID Document',
        description: 'Government-issued ID of the authorized representative'
      },
      { 
        value: 'vat_registration', 
        label: 'VAT Registration',
        description: 'VAT registration certificate if applicable'
      },
    ];
  
    const handleFileSelect = (event) => {
      const file = event.target.files[0];
      if (file) {
        setSelectedFile(file);
      }
    };
  
    const handleUpload = async () => {
      if (!selectedFile || !documentType) return;
      
      setUploading(true);
      try {
        await onUpload(selectedFile, documentType);
        setUploadDialog(false);
        setSelectedFile(null);
        setDocumentType('');
      } finally {
        setUploading(false);
      }
    };
  
    const getStatusChipColor = (status) => {
      switch (status) {
        case 'verified':
          return 'success';
        case 'pending':
          return 'warning';
        case 'rejected':
          return 'error';
        default:
          return 'default';
      }
    };
  
    return (
      <>
        <Card>
          <CardHeader 
            title="Required Documents"
            subheader="Upload the necessary documents for regulatory compliance"
            action={
              <Button
                variant="contained"
                startIcon={<UploadIcon />}
                onClick={() => setUploadDialog(true)}
              >
                Upload Document
              </Button>
            }
          />
          <CardContent>
            <Grid container spacing={3}>
              {documentTypes.map((type) => {
                const uploadedDoc = documents.find(doc => doc.type === type.value);
                
                return (
                  <Grid item xs={12} key={type.value}>
                    <Card variant="outlined">
                      <CardContent>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Box>
                            <Typography variant="h6" gutterBottom>
                              {type.label}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {type.description}
                            </Typography>
                          </Box>
                          
                          {uploadedDoc ? (
                            <Box display="flex" alignItems="center" gap={1}>
                              <Typography variant="body2">
                                {uploadedDoc.name}
                              </Typography>
                              <Chip 
                                label={uploadedDoc.status}
                                color={getStatusChipColor(uploadedDoc.status)}
                                size="small"
                              />
                              <IconButton
                                size="small"
                                color="error"
                                onClick={() => onDelete(uploadedDoc.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          ) : (
                            <Button
                              variant="outlined"
                              size="small"
                              startIcon={<UploadIcon />}
                              onClick={() => {
                                setDocumentType(type.value);
                                setUploadDialog(true);
                              }}
                            >
                              Upload
                            </Button>
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </CardContent>
        </Card>
  
        {/* Upload Dialog */}
        <Dialog 
          open={uploadDialog} 
          onClose={() => !uploading && setUploadDialog(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Upload Document</DialogTitle>
          <DialogContent>
            <Box sx={{ pt: 2 }}>
              <FormControl fullWidth sx={{ mb: 3 }}>
                <InputLabel>Document Type</InputLabel>
                <Select
                  value={documentType}
                  onChange={(e) => setDocumentType(e.target.value)}
                  label="Document Type"
                >
                  {documentTypes.map(type => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
  
              <Button
                variant="outlined"
                component="label"
                fullWidth
                startIcon={<UploadIcon />}
              >
                Select File
                <input
                  type="file"
                  hidden
                  accept=".pdf,.jpg,.jpeg,.png"
                  onChange={handleFileSelect}
                />
              </Button>
  
              {selectedFile && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Selected: {selectedFile.name}
                </Typography>
              )}
  
              <Alert severity="info" sx={{ mt: 2 }}>
                Accepted formats: PDF, JPG, PNG. Maximum file size: 5MB
              </Alert>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={() => setUploadDialog(false)}
              disabled={uploading}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleUpload}
              disabled={!selectedFile || !documentType || uploading}
              startIcon={uploading ? <CircularProgress size={20} /> : null}
            >
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  
  export default AccountPage;